.select-kit {
  &.combo-box {
    &.tag-drop {
      min-width: auto;

      .tag-drop-header,
      .selected-name {
        color: var(--primary-high);
      }

      .select-kit-row {
        &[data-value="all-tags"],
        &[data-value="no-tags"] {
          color: var(--tertiary);
          font-weight: 700;
        }
      }

      .caret-icon {
        color: var(--primary-high);
      }

      &.has-selection {
        .tag-drop-header {
          color: var(--d-nav-border-color--active);
          border-color: var(--d-nav-border-color--active);
        }
      }
    }
  }
}
