[data-content][data-identifier="user-status-message-tooltip"] {
  .fk-d-tooltip__inner-content {
    gap: 0.65em;
  }

  .emoji {
    width: 1em;
    height: 1em;
  }

  .user-status-message-description {
    margin-left: 0.1rem;
    color: var(--primary-800);
  }

  .user-status-tooltip-until {
    color: var(--primary-medium);
    white-space: nowrap;
    margin-left: 0.15em;
  }
}
