// styles that apply to the popup that appears when you show the edit history of a post
.modal.history-modal {
  .d-modal__footer {
    justify-content: space-between;
  }

  #revision-numbers {
    display: inline-block;
    min-width: 7em;
    text-align: center;
  }

  #revision {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.5em 0;
    align-items: center;
    padding-bottom: 0.5em;
    overflow: auto;
    border-bottom: 3px solid var(--primary-low);
    @include breakpoint(tablet) {
      padding-bottom: 0;
    }
    @include breakpoint(mobile-large) {
      padding-bottom: 0.5em;
    }
  }

  #revision-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5em;
  }

  #display-modes {
    .nav {
      margin: 0;
      padding: 0;
      border: none;
    }
    @include breakpoint(tablet) {
      width: 100%;
      margin-top: 0.5em;

      .nav-pills {
        width: 100%;

        li {
          margin: 0;
          flex: 1 1 auto;
        }

        a {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }

  .revision-details__user {
    display: flex;
    align-items: center;
    gap: 0.25em;
  }

  .revision__title,
  .body-diff,
  table.markdown > tbody > tr,
  .-tag-revisions {
    --gap-width: 1rem;
    display: flex;
    gap: 0 var(--gap-width);
  }

  .mobile-view & {
    .-tag-revisions {
      display: block;
    }

    .discourse-tags {
      display: inline;
      font-size: var(--font-down-1);
    }
  }

  .inline-diff {
    width: 100%;
    max-width: var(--modal-max-width);
  }

  pre {
    display: flex;
  }

  pre code {
    word-wrap: anywhere; // prevent long strings from breaking modal width
    min-width: 0;
    overflow: auto;
    flex: 0 1 auto;
  }

  .revision__title {
    margin: 0;
    line-height: var(--line-height-medium);
  }

  &:not(.--mode-inline) {
    .-tag-revisions span {
      flex: 0 1 50%;
      min-width: 0;
      align-self: start;
    }
  }

  .revision-content {
    flex: 0 1 50%;
    min-width: 0;
  }

  .markdown {
    font-family: var(--d-font-family--monospace);
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    td {
      word-wrap: anywhere;
      white-space: pre-wrap;
      flex: 0 1 50%;
    }

    tbody {
      border: none;
    }
  }

  #revision-controls {
    display: flex;
    align-items: center;

    .btn {
      margin: 0;
    }

    @include breakpoint(tablet) {
      width: 100%;
      justify-content: space-between;
    }
  }

  [class^="revision-controls--"] {
    display: flex;
    gap: 0 1em;
  }

  #revisions {
    word-wrap: break-word;

    table {
      margin-top: 10px;

      tr {
        border: none;
      }
    }

    .row:first-of-type {
      margin-top: 10px;
    }

    .revision-content table {
      thead {
        th {
          padding-bottom: 2px;
          font-weight: bold;
          color: var(--primary);
        }
      }

      td {
        padding: 3px 3px 3px 0.5em;

        img {
          max-width: none;
        }
      }
    }
  }

  #revision-footer-buttons {
    display: flex;
    gap: 0.5em;

    button {
      margin: 0;
      flex: 1 1 auto;
      min-width: 0;

      .d-button-label {
        @include ellipsis;
      }
    }
    @include breakpoint(tablet) {
      width: 100%;
      flex: 1 1 100%;

      button {
        font-size: var(--font-down-1);
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
  }

  ins,
  .diff-ins,
  del,
  .diff-del {
    color: var(--primary);

    &:not(s, .bbcode-u, .bbcode-s) {
      text-decoration: none;
    }

    code,
    img {
      border: 2px solid;
    }

    a {
      text-decoration: none;
    }
  }

  ins,
  .diff-ins {
    background: var(--success-low);

    code,
    img {
      border-color: var(--success);
    }

    img {
      opacity: 0.75;
      filter: alpha(opacity=75);
    }

    a {
      color: var(--success);
    }
  }

  del,
  .diff-del {
    background: var(--danger-low);

    code,
    img {
      border-color: var(--danger);
    }

    img {
      opacity: 0.5;
      filter: alpha(opacity=50);
    }

    a {
      color: var(--danger);
    }
  }

  span.date {
    font-weight: bold;
  }

  span.edit-reason {
    background-color: var(--highlight-bg);
  }

  .d-icon-ban {
    color: var(--danger);
  }

  .hidden-revision-either {
    opacity: 0.5;
  }

  .hidden-revision-previous .row {
    div:nth-of-type(1),
    td:nth-of-type(1) {
      opacity: 0.5;
    }
  }

  .hidden-revision-current .row {
    div:nth-of-type(2),
    td:nth-of-type(2) {
      opacity: 0.5;
    }
  }
}
