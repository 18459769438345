.sidebar-tags-form {
  height: 100%;
  display: grid;
  gap: 0 0.5em;
  margin: 0;
  grid-template-columns: repeat(auto-fill, minmax(12em, 1fr));
  align-items: flex-start;
  grid-auto-rows: min-content;

  &__no-tags {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  .sidebar-tags-form__tag {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    .sidebar-tags-form__tag-label {
      margin-bottom: 0;
      min-width: 0;
      padding: 0.33em 0;
      font-weight: normal;
      @include breakpoint(tablet) {
        // extra tappable space
        padding: 0.5em 0;
      }

      p {
        overflow: hidden;
        margin: 0;
      }

      &-name {
        // avoid long tags breaking the layou
        word-break: break-all;
      }
    }

    .sidebar-tags-form__input {
      margin-top: 0;
    }
  }

  .sidebar-tags-form__tag-label-count {
    color: var(--primary-medium);
  }
}
