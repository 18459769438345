a.hashtag {
  color: var(--primary-or-primary-low-mid);
  font-weight: bold;

  &:visited,
  &:hover {
    color: var(--primary-or-primary-low-mid);
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
}

.hashtag-cooked {
  @include mention;

  &:visited,
  &:hover {
    color: var(--primary);
  }

  .hashtag-icon-placeholder {
    width: 0.72em;
    height: 0.72em;
    display: inline-block;
    color: var(--primary-medium);
  }

  .d-icon,
  .hashtag-icon-placeholder {
    font-size: var(--font-down-2);
    margin: 0 0.3em 0 0;
  }

  img.emoji {
    width: 15px;
    height: 15px;
    vertical-align: text-bottom;
  }

  svg {
    display: inline;
  }

  .hashtag-category-badge {
    flex: 0 0 auto;
    width: 0.72em;
    height: 0.72em;
    margin-right: 0.25em;
    margin-left: 0.1em;
    display: inline-block;
  }
}

.hashtag-autocomplete {
  max-height: 13.5em;
  overflow-y: auto;
  box-shadow: var(--shadow-dropdown);
  border-radius: 8px;

  &__fadeout {
    height: inherit;
    max-height: inherit;
    overflow-y: auto;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1.5em;
      background: linear-gradient(
        to bottom,
        rgba(var(--secondary-rgb), 0),
        rgba(var(--secondary-rgb), 100%)
      );
    }
  }

  &__option {
    &:last-of-type {
      .hashtag-autocomplete__link {
        position: relative;
        z-index: 1;
      }
    }
  }

  &__link {
    align-items: center;
    display: flex;

    &.selected {
      font-weight: bold;
    }

    .d-icon {
      margin-right: 0.25em;
    }

    .hashtag-category-badge {
      flex: 0 0 auto;
      width: 15px;
      height: 15px;
      margin-right: 5px;
      display: inline-block;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    gap: 0.25em;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__meta-text {
    color: var(--primary-700);
    font-size: var(--font-down-1);
  }
}
