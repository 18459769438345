.sidebar-custom-sections {
  .sidebar-section-wrapper {
    padding-bottom: 0;
  }

  .sidebar-section-header {
    display: flex;
  }

  .sidebar-section[data-section-name="community"]
    .sidebar-section-link-prefix.icon {
    cursor: pointer;
  }

  a {
    -webkit-touch-callout: none !important;
    @include unselectable;
    cursor: pointer;
  }

  .sidebar-section-wrapper.disabled {
    a {
      pointer-events: none;
    }

    .sidebar-section-link-wrapper {
      .sidebar-section-link-prefix.icon,
      .sidebar-section-link {
        background: none;
        color: var(--primary-low-mid);
      }

      .sidebar-section-link.drag {
        font-weight: bold;
        color: var(--primary-high);

        .sidebar-section-link-prefix.icon {
          color: var(--primary-high);
        }
      }
    }
  }
}

.discourse-touch {
  .sidebar-custom-sections {
    a:hover {
      background: none !important;
    }
  }
}
