// IMPORTANT: This stylesheet needs to work for super old browsers, including those
// without support for `var()`. Therefore every color definition needs to define a simple
// value first, as a fallback
body.crawler,
body > noscript {
  font-family: serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: serif;
  }

  &.browser-update {
    header {
      height: 50px;
      margin-bottom: 1em;
    }

    .buorg {
      position: absolute;
      top: 50px;
      width: 100%;
      background: #fdf2ab;
      text-align: center;
      font-family: sans-serif;
      color: #000;
      font-size: 14px;
      margin-bottom: 10px;
    }

    #topic-title {
      margin-top: 2em;
    }
  }

  &::after,
  &::before {
    // common way to show fixed background images
    display: none;
  }

  a {
    // we want all links to look like links
    color: var(--tertiary) !important;
    text-decoration: underline !important;
  }

  > header {
    // site header
    box-sizing: border-box;
    width: 100%;
    top: 0;
    background-color: var(--header_background);
    padding: 10px;
    box-shadow: none;
    border-bottom: 1px solid var(--header_primary-medium);
  }

  .header-buttons {
    display: none;
  }

  // topic list
  div#main-outlet {
    padding: 10px;

    div.post {
      word-break: break-word;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .topic-list {
    table-layout: fixed;
    overflow: hidden;
    margin: 2em 0;

    thead {
      border-bottom: 1px solid var(--primary_low);

      th {
        padding: 0 0 0.5em;
      }

      th:nth-child(2) {
        display: none;
      }

      th:first-of-type {
        width: 60%;
        padding-left: 0;
      }

      th:last-of-type,
      th.replies,
      th.views {
        text-align: center;
      }

      th:last-of-type,
      th.replies {
        width: 20%;
      }

      th.posters {
        display: none;
      }
    }

    td {
      padding: 10px 0;
    }

    td.posters {
      display: none;
    }

    td:last-of-type,
    td.replies,
    td.views {
      text-align: center;
    }

    td:first-of-type {
      width: 60%;
    }

    td:last-of-type,
    td.replies {
      width: 20%;
    }

    @media (max-width: 850px) {
      table-layout: auto;
      margin: 0;

      td {
        word-break: break-word;

        &.posters {
          a:not(:last-of-type) {
            display: none;
          }

          a:last-of-type {
            display: block;
          }
        }
      }

      td,
      th {
        &.views {
          display: none;
        }
      }

      .link-top-line {
        a.title {
          padding: 0;
        }
      }

      .link-bottom-line {
        margin-top: 0.25em;
      }
    }

    .topic-list-item {
      border-bottom: 1px solid var(--primary-low);

      > * {
        padding: 0.75em 0;
      }

      td.main-link {
        padding-right: 1em;
      }

      p.excerpt {
        font-size: var(--font-down-1);
      }
    }
  }

  .topic-category {
    display: inline;
  }

  .topic-list-main-link a.title,
  .topic-list .main-link a.title,
  .latest-topic-list-item .main-link a.title {
    padding: 0;
  }

  .topic-list .link-bottom-line {
    margin-top: 0.25em;
  }

  // topics
  div#main-outlet {
    div.post {
      word-break: break-word;
      overflow: auto;
      max-width: 100%;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .topic-body {
      float: unset;
    }
  }

  .crawler-post {
    margin-top: 1em;
    margin-bottom: 2em;
    padding-top: 1.5em;
    border-top: 1px solid var(--primary-low);
  }

  .crawler-post-meta {
    margin-bottom: 1em;

    .creator {
      word-break: break-all;

      a {
        font-weight: bold;
      }
      @include breakpoint(tablet) {
        display: inline-block;
        margin-bottom: 0.25em;
      }
    }
  }

  .crawler-post-infos {
    color: #666;
    display: inline-block;
    @include breakpoint(tablet, min-width) {
      float: right;
    }

    [itemprop="position"] {
      float: left;
      margin-right: 0.5em;
    }
  }

  .crawler-linkback-list {
    margin-top: 1em;

    a {
      display: block;
      padding: 0.5em 0;
      border-top: 1px solid var(--primary-low);
    }
  }

  .tag-crawler {
    .tag-box {
      margin-bottom: 0.5em;
    }
  }

  #topic-title {
    > * {
      display: block;
    }

    h1 {
      margin-bottom: 0.25em;
    }
  }

  .poll-info {
    // crawler vote count always shows 0
    display: none;
  }

  pre,
  code,
  blockquote,
  aside.quote .title {
    background: var(--primary-low);
  }

  .md-table {
    tr {
      border: 1px solid var(--primary-low);
    }

    th {
      font-weight: bold;
    }

    td,
    th {
      padding: 0.25em;
      border-right: 1px solid var(--primary-low);
    }
  }

  // footer
  footer {
    margin-top: 2em;
  }

  .noscript-footer-nav {
    margin-top: 4em;

    a {
      margin-right: 0.25em;
      white-space: nowrap;
    }
  }

  #noscript-footer {
    padding: 0 10px;
    text-align: center;
  }

  .crawler-nav {
    margin: 1em 0;

    ul {
      margin: 0;
      list-style-type: none;
    }

    li {
      display: inline-block;
    }

    a {
      display: inline-block;
      padding: 0.5em 1em 0.5em 0;
    }
  }
}

.buorg div {
  padding: 8px;
}

.buorg a,
.buorg a:visited {
  color: #e25600;
  text-decoration: underline;
}
@media print {
  .buorg {
    display: none !important;
  }
}

.user-crawler {
  .username {
    margin-left: 5px;
    display: inline-block;
  }
}
