.form-kit__field-code {
  .ace-wrapper {
    width: 100%;

    > .loading-container {
      width: 100%;
    }
  }

  .ace-wrapper[data-disabled="true"] {
    opacity: 0.5;

    .ace_scroller {
      cursor: not-allowed !important;
    }
  }

  .ace-wrapper[data-disabled="false"] {
    .ace_focus {
      @include default-input;
      height: unset;
      border-color: var(--tertiary);
      outline: 2px solid var(--tertiary);
      outline-offset: -1px;
    }
  }
}

.form-kit__control-code {
  height: 250px !important;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--primary-400);
  border-radius: var(--d-input-border-radius);

  .form-kit__field.has-error & {
    border-color: var(--danger);
  }
}
