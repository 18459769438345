.form-kit__control-textarea {
  @include default-input;

  // reset textarea styles
  width: 100% !important;
  margin: 0 !important;
  min-width: auto !important;
  padding: 0.5em !important;
  height: 150px !important;

  // prevents firefox/chrome to add spacing under textarea
  display: block;
  border-radius: var(--d-input-border-radius);
}
