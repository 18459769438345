#main-outlet {
  --below-topic-margin: 0.75em;
}

.container.posts {
  display: grid;
  grid-template-areas: "posts timeline";
  grid-template-columns: auto auto;
  margin-bottom: var(--below-topic-margin);

  > .row {
    grid-area: posts;
    max-width: calc(
      100vw - 20px
    ); // 20px is the left + right padding on .wrap in common/base/discourse.scss
    min-width: 0; // grid needs to be able to shrink
  }

  .timeline-container {
    margin-left: unset !important;

    /* This is a temporary override to ease the transition
    to the sticky position timeline for themes with custom timeline positioning.
    Without this those themes would render topics unreadable. */
  }

  .topic-navigation {
    overflow-anchor: none;
  }

  // timeline
  @media screen and (min-width: 925px) {
    // at 925px viewport width and above the timeline is visible (see topic-navigation.js)
    .topic-navigation {
      grid-area: timeline;
      align-self: start;
      @include sticky;
      top: calc(var(--header-offset, 60px) + 2em);
      margin-left: 1em;
      z-index: z("timeline");

      &.with-topic-progress {
        align-self: end;
      }

      &.topic-progress-expanded {
        z-index: z("fullscreen");
      }
    }

    .topic-navigation-popup {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      overflow-y: auto;
      z-index: z("timeline");
      padding: 10px 10px 35px 10px;
      box-shadow: var(--shadow-dropdown);
      background: var(--tertiary-low);

      .close {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        top: 8px;
        color: var(--primary);
        opacity: 0.5;
        font-size: var(--font-up-1);
      }
    }
  }

  // progress bar
  @media screen and (max-width: 924px) {
    grid-template-areas: "posts";
    grid-template-columns: auto;

    .topic-navigation {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      grid-area: posts;
      grid-row: 3;
      width: auto;
    }

    .timeline-container:not(.timeline-fullscreen) {
      display: none; // hiding this because sometimes the JS switch lags and causes layout issues
    }

    .timeline-container .timeline-scroller-content {
      position: relative;
    }
  }
}

.progress-back-container {
  z-index: z("dropdown");
  text-align: center;
  margin-bottom: 0;
  margin-right: 0.5em;
  position: absolute;
  right: 0;
  bottom: 100%;

  .btn {
    margin: 0;
  }
}

.topic-status-info,
.topic-timer-info {
  border-top: 1px solid var(--primary-low);
  margin: 0;
  max-width: calc(
    var(--topic-body-width) + (var(--topic-body-width-padding) * 2) +
      var(--topic-avatar-width)
  );

  &:empty {
    padding: 0;
  }

  span .d-icon {
    font-size: var(--font-down-1);
  }

  .topic-timer-heading,
  .slow-mode-heading {
    display: flex;
    align-items: center;
    margin: 0;
    padding: var(--below-topic-margin) 0;
  }

  .slow-mode-remove,
  .topic-timer-modify {
    display: flex;
    margin-left: auto;
    align-self: flex-start;
  }

  button {
    font-size: var(--font-down-2);
    background: transparent;
  }
}

.title-wrapper {
  display: flex;
  flex-wrap: wrap;

  .topic-statuses {
    line-height: 1.2;

    .d-icon {
      color: var(--primary-medium);
    }
  }

  .header-title .private-message-glyph-wrapper {
    float: left;
    margin-right: 0.25em;
  }
}

#topic-title {
  .title-wrapper {
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: 925px) {
      width: 90%; // topic title isn't full-width on wide screens
    }
  }

  h1 {
    margin-bottom: 0;
    width: 100%;
  }

  a.edit-topic .d-icon {
    font-size: 0.8em;
  }

  .edit-topic-title {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    gap: 0.5em;
    width: 100%;
    max-width: calc(
      var(--topic-body-width) + (var(--topic-body-width-padding) * 2) +
        var(--topic-avatar-width)
    );

    .select-kit .category-row {
      max-width: unset;
    }
  }

  .edit-title__wrapper {
    flex: 1 1 100%;

    #edit-title {
      width: 100%;
      margin: 0;
    }
  }

  .edit-category__wrapper {
    flex: 1 1 5%;
    @include breakpoint(tablet) {
      min-width: 0; // allows category name to shrink to fit narrow screens
    }

    .select-kit.combo-box.category-chooser {
      width: 100%;
    }
  }

  .edit-tags__wrapper {
    flex: 1 1 33%;
    @include breakpoint(tablet) {
      flex: 1 1 100%; // force full row on narrow screens
    }

    .mini-tag-chooser {
      width: 100%;
    }

    .select-kit-header--filter {
      flex-wrap: nowrap; // forces the whole input to wrap if needed, rather than individual tags
      min-width: 0;
      @include breakpoint(tablet) {
        flex-wrap: wrap; // individual tags will need to wrap on narrow screens
      }

      button {
        min-width: 0;
      }
    }

    .multi-select-filter {
      flex-shrink: 0;
      min-width: 2em; // always provide a minimal space for input
    }
  }

  .edit-controls {
    display: flex;
    width: 100%;
    gap: 0.5em;
  }
}

.private-message-glyph {
  color: var(--primary-medium);
  height: 0.95em;
}

.private_message {
  background: var(--d-content-background);

  #topic-title {
    .edit-topic-title {
      position: relative;
      min-width: 0;

      .private-message-glyph {
        position: absolute;
        z-index: 1;
        left: 0.75em;
        top: 1.2em;

        .mobile-view & {
          top: 1.1em;
        }
      }

      #edit-title {
        padding-left: 2.25em;
      }

      .mini-tag-chooser {
        margin-left: 0;
        @include breakpoint(mobile-large, min-width) {
          .selected-name {
            max-width: 500px;
          }
        }
      }
    }
  }
}

.topic-title-outlet {
  clear: both;
}

.has-pending-posts {
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
  background-color: var(--highlight-bg);
  margin-top: 1em;
  max-width: 757px;
}

.post-links-container {
  @include unselectable;
  clear: both;

  .post-links {
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid var(--primary-low);

    li:last-of-type {
      margin-bottom: 1em;
    }
  }

  .expand-links {
    color: var(--primary-med-or-secondary-med);
  }

  .track-link {
    display: flex;
    align-items: center;

    span:not(.badge) {
      @include ellipsis;
    }
  }

  ul {
    margin: 0;
    list-style: none;

    li {
      margin-bottom: 0.5em;

      a[href] {
        color: var(--primary-med-or-secondary-med);
      }

      .d-icon {
        font-size: var(--font-down-2);
        margin: 0 0.5em 0 0;
      }
    }
  }

  a.reply-new {
    .d-icon {
      background: var(--secondary);
      border-radius: 20px;
      transition: all linear 0.15s;
    }

    &:hover {
      color: var(--tertiary);

      .d-icon {
        background: var(--tertiary-low);
      }
    }
  }
}

a.topic-featured-link {
  display: inline-flex;
  align-items: center;
  text-transform: lowercase;
  color: var(--primary-med-or-secondary-med);
  font-size: 0.875rem;

  .d-icon {
    font-size: var(--font-down-1);
    margin-right: 0.25em;
  }
}

.topic-area {
  .pending-posts {
    max-width: calc(
      var(--topic-body-width) + (var(--topic-body-width-padding) * 2) +
        var(--topic-avatar-width)
    );

    .reviewable-item {
      .post-body {
        max-height: unset;
      }
    }
  }
}

.published-page-notice {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;
  max-width: calc(
    var(--topic-body-width) + (var(--topic-body-width-padding) * 2) +
      var(--topic-avatar-width)
  );
  align-items: center;

  .is-public {
    padding: 0.25em 0.5em;
    font-size: var(--font-down-2);
    background: var(--tertiary);
    color: var(--secondary);
    border-radius: 3px;
    text-transform: lowercase;
  }
}

a[data-clicks]::after {
  @include click-counter-badge;
}
