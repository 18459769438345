/* Default badge styles. */
.show-badge {
  background: var(--d-content-background);
}

.user-badge {
  padding: 3px 8px;
  color: var(--primary);
  border: 1px solid var(--primary-low);
  line-height: var(--line-height-large);
  display: inline-flex;
  align-items: center;
  background-color: var(--secondary);
  margin: 4px 0 0;

  img {
    height: 16px;
    width: 16px;
  }

  .badge-display-name {
    margin-left: 0.25em;
  }

  &.badge-type-gold .fa {
    color: var(--gold) !important;
  }

  &.badge-type-silver .fa {
    color: var(--silver) !important;
  }

  &.badge-type-bronze .fa {
    color: var(--bronze) !important;
  }

  &.disabled {
    color: var(--primary-medium);
    background-color: var(--primary-very-low);

    .fa {
      opacity: 0.4;
    }
  }
}

@media all and (max-width: 750px) {
  .show-badge .user-badge-with-posts .badge-user a.post-link {
    width: auto;
  }

  .show-badge div.badge-user {
    padding: 0;
  }

  .badges-listing {
    display: block;

    .info,
    .grant-count {
      text-align: left;
    }

    .row > div.info {
      display: none;
    }

    .row {
      display: block;

      > div {
        display: block;
        margin-top: 10px;
      }
    }
  }
}

.show-badge .badge-user-info {
  .earned {
    font-size: var(--font-up-2);
    margin-bottom: 1em;
  }
}

.show-badge .single-user {
  margin-left: 2%;
  padding-bottom: 20px;

  .load-more {
    padding-top: 30px;
    display: block;
    font-size: var(--font-up-2);
  }
}

.show-badge .single-user .badge-user {
  padding-left: 0;
  text-align: left;
  display: block;
  margin: 20px 0;

  .badge-info {
    display: none;
  }

  .date {
    display: inline-block;
    font-size: var(--font-up-1);
    margin-left: 10px;
  }

  .post-link {
    font-size: var(--font-up-2);
    width: 500px;
    margin: 0;
    padding: 0;
  }
  width: 800px;

  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

.long-description.banner {
  width: 88%;
  margin-bottom: 15px;
  margin-top: 15px;
}

.badge-card {
  background-color: var(--primary-very-low);
  border: 1px solid var(--primary-low);
  position: relative;

  .check-display {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .badge-granted {
    display: block;
    margin-top: 0.5em;
    color: var(--primary-medium);
    font-size: var(--font-down-1);
  }

  .grant-count {
    font-weight: bold;
    color: var(--primary-medium);
    line-height: 1;
  }

  .grant-text {
    color: var(--primary-medium);
    font-size: var(--font-down-1);
  }

  .favorite-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .badge-contents {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 1.5em 1.5em;

    .badge-link {
      color: var(--primary);
      display: inline-block;
      line-height: var(--line-height-medium);

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }

    .badge-summary:has(a) {
      // for summary links to be reachable
      // they must be positioned above .badge-link:after
      position: relative;
      z-index: 1;
    }

    .badge-icon {
      --badge-icon-size: 3.5em;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;

      svg {
        font-size: var(--badge-icon-size);
      }

      img {
        width: 100%;
        max-width: var(--badge-icon-size);
        max-height: var(--badge-icon-size);
      }

      &.badge-type-gold .fa {
        color: rgb(231, 195, 0) !important;
      }

      &.badge-type-silver .fa {
        color: #c0c0c0 !important;
      }

      &.badge-type-bronze .fa {
        color: #cd7f32 !important;
      }
    }

    .badge-info {
      color: var(--primary);

      h3 {
        margin-bottom: 0.25em;
        font-size: var(--font-up-1);
        @media screen and (min-width: 900px) {
          font-size: var(--font-up-2);
        }
      }
    }
  }

  &.--badge-large {
    width: 100%;
    align-self: flex-start;

    @media screen and (min-width: 767px) {
      max-width: calc(var(--d-max-width) / 2);
    }

    .badge-contents {
      h3 {
        font-size: var(--font-up-3);
      }
    }
  }
}

.badges-granted {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  gap: 3em 2em;
}

.container.badges {
  background: var(--d-content-background);
}

.badge-groups {
  margin: 20px 0;
  color: var(--primary-med-or-secondary-med);

  h3 {
    margin-bottom: 1em;
  }
}

.badge-title {
  .user-content {
    padding: 0;
  }
}

.badge-group-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  grid-gap: 1em;
  width: 100%;
  margin-bottom: 1.5em;

  .title {
    font-size: var(--font-up-1);
  }
}

.show-badge-details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 2em;
  margin-top: 1em;
  gap: 1em;

  .badge-grant-info {
    &.hidden {
      display: none;
    }
  }

  .grant-info-item {
    margin-bottom: 1em;
    color: var(--primary-med-or-secondary-med);

    &:first-of-type {
      margin-right: 10px;
    }
  }

  .badge-set-title {
    flex: 0 1 auto;
    padding: 1.5em;
    border: 1px solid var(--primary-low);

    .badge-title {
      height: 100%;

      &__saved {
        color: var(--success);
      }
    }

    .control-group:last-child {
      margin: auto 0 0;
    }

    .form-horizontal {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin: 0;
    }

    .controls {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      gap: 0.5em;
      margin: 0;

      button:last-of-type {
        margin-right: auto;
      }
    }

    .combobox {
      width: 100%;
    }
  }
}

.check-display {
  display: flex;
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  align-items: center;
  justify-content: center;

  .d-icon {
    font-size: var(--font-down-2);
    color: var(--secondary);
  }
}

.check-display.status-checked {
  background-color: var(--success);
}

.check-display.status-unchecked {
  background-color: var(--danger);
}

.hyperlink {
  cursor: pointer;
}
