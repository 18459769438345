// styles for the category badge color picker
.category-color-editor {
  input {
    width: 5.5em;
    margin-right: 1em;
  }

  .color-title {
    display: block;
    margin: 1em 0 0.25em;
  }

  .colorpicker-wrapper {
    display: flex;
    align-items: flex-start;
  }

  .colors-container {
    display: inline-flex;
    flex-wrap: wrap;

    .colorpicker {
      position: relative;
      margin: 0 0.125em 0.25em;
      border: 1px solid var(--primary-medium);
      width: 1.25em;
      height: 1.2em;
      padding: 0;
      flex: 0 0 auto;
      color: white;

      svg {
        display: none;
      }

      &.used-color svg {
        display: inline-flex;
        opacity: 0.8;
        max-width: 70%;
      }
    }
  }
}
