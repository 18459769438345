.about {
  &__main-content {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    max-width: 1100px;
  }

  &__header {
    max-width: 1100px;
  }

  &__left-side {
    flex: 1 1 650px;
  }

  &__right-side {
    flex: 1 2 200px;
  }

  &__stats {
    display: flex;
    border-top: 1px solid var(--primary-low);
    border-bottom: 1px solid var(--primary-low);
    padding: 1em 1em;
    margin-bottom: 1em;

    @include breakpoint(tablet) {
      flex-direction: column;
    }

    .d-icon {
      margin-right: 3px;
    }
  }

  &__stats-item {
    flex: 1 0 fit-content;

    @include breakpoint(tablet) {
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__banner {
    margin-bottom: 1em;
    width: 100%;
    height: auto; // Let the height scale with the width
    position: relative; // Ensure the container can handle absolute positioning for the image
    padding-top: 27.27%; // Maintain the aspect ratio (300px/1100px * 100)

    &-img {
      position: absolute; // Allow to fill the full space
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; // Ensures the image fills the container while preserving aspect ratio
    }
  }

  &__activities-item {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
  }

  &__activities-item-icon {
    margin-right: 1em;
  }

  &__activities-item-period {
    font-size: var(--font-down-2);
  }

  &__admins,
  &__moderators {
    margin-top: 3em;

    h3 {
      margin-bottom: 1em;
    }
  }
}

.about-page-users-list {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(14em, 1fr));

  &__expand-button {
    width: 100%;
  }
}
