.select-kit.single-select {
  .select-kit-filter {
    display: flex;

    &.is-expanded {
      border-bottom: 1px solid var(--primary-low);
    }
  }

  &.is-expanded .select-kit-header:not(.btn),
  .select-kit-header:not(.btn):focus,
  .select-kit-header:not(.btn):active {
    @include default-focus;
  }

  .select-kit-header:focus {
    @include default-focus;
  }

  .select-kit-header.btn:focus,
  .select-kit-header.btn:active {
    outline: none;
  }

  &.is-disabled {
    .select-kit-header {
      opacity: 0.5;
    }
  }
}
