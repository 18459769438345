// --------------------------------------------------
// Topic lists
// --------------------------------------------------

// Base list
// --------------------------------------------------
.topic-list-icons {
  .d-icon-thumbtack {
    color: var(--primary-med-or-secondary-med);
  }

  .d-icon-thumbtack.unpinned {
    color: var(--primary-med-or-secondary-med);
  }

  a.title {
    color: var(--primary);
  }

  .d-icon-bookmark {
    color: var(--primary-med-or-secondary-med);
  }
}

.topic-list {
  @extend .topic-list-icons;
  margin: 0 0 10px;

  .topic-list-data {
    padding: 0.8em 0.33em;

    &:first-of-type {
      padding-left: 0.67em;
    }

    &:last-of-type {
      padding-right: 0.67em;
    }

    th & {
      border-bottom: 3px solid var(--primary-low);
    }
  }

  button.bulk-select {
    padding: 0;
    margin-right: 0.5em;
    line-height: var(--line-height-large);
  }

  .topic-list-data.bulk-select {
    padding: 0;
    width: 30px;

    label {
      margin: 0;
      padding: 0.8em 0.67em 1.1em 0.67em;
      cursor: pointer;
    }

    + .main-link {
      padding-left: 0;
    }
  }

  .topics-replies-toggle {
    background: none;
    border: none;
    line-height: var(--line-height-large);
    padding-left: 0.5em;
    padding-right: 0.5em;
    position: relative;

    &.active {
      @include nav-active;
    }
  }

  $td-posters-height: 29px; // min-height of td with avatar glow
  $td-posters-more-lh: $td-posters-height - 4;

  .posters {
    // we know there are up to 5 avatars of fixed size
    // will be overridden by media width queries on narrow displays to 1 avatar's width
    width: 146px;

    > a {
      float: left;
      margin-right: 4px;

      &:last-of-type {
        margin-right: 0;
      }

      &.posters-more-count {
        cursor: default;
        color: var(--primary-med-or-secondary-med);
        line-height: $td-posters-more-lh;
        font-size: var(--font-down-1);
      }
    }
  }

  .topic-list-data.posters {
    height: $td-posters-height;
  }

  .posters a:first-child .avatar.latest:not(.single) {
    box-shadow: 0 0 3px 1px rgba(var(--tertiary-rgb), 0.35);
    border: 1px solid rgba(var(--tertiary-rgb), 0.5);
    position: relative;
    left: -2px;
  }

  .likes {
    width: 4.3em;
  }

  .views {
    width: 4.3em;
  }

  .posts {
    width: 4.3em;
  }

  .post-actions {
    clear: both;
    width: auto;
    color: var(--primary-med-or-secondary-med);
    text-align: left;
    font-size: var(--font-down-1);
    margin-top: 5px;

    .fa {
      margin-right: 2px;
    }

    a {
      color: var(--primary-med-or-secondary-med);
      margin-right: 3px;
      line-height: var(--line-height-large);
    }
  }

  .activity {
    width: 4em;

    &:lang(zh_CN) {
      width: 5.3em;
    }
  }

  .age {
    width: 4em;
  }

  .with-year {
    white-space: nowrap;
  }
}

.topic-list-bottom {
  margin: 1.25em 0;

  .dismiss-container-bottom {
    float: right;
  }

  .--no-topics-education + & {
    margin: 0;

    .footer-message {
      padding-top: 4em;
    }
  }
}

// Misc. stuff
// --------------------------------------------------
#list-area .top-lists {
  h2 {
    cursor: pointer;
    margin: 5px 0 10px;
  }

  .period-chooser .select-kit-body {
    width: 275px;
  }
}

#list-area {
  h2 {
    margin: 20px 0 10px;
  }

  .show-more {
    width: 100%;
    z-index: z("base");
    position: absolute;
    top: 0;

    .alert {
      margin: 0;
      padding: 1.1em 2em 1.1em 0.65em;
      gap: 0.65em;
      align-items: center;

      &.loading {
        color: var(--primary-medium);
        cursor: default;
      }
    }
  }
}

.bulk-select-topics {
  display: inline-flex;
  margin-left: -5px;

  .btn {
    margin-right: 0.5em;
  }
}

.dismiss-container-top {
  display: flex;
  justify-content: flex-end;
}

.category-breadcrumb {
  // only target the top-level li, not dropdowns
  .select-kit {
    align-self: stretch;
    height: 100%;
  }
}

.category-logo.aspect-image {
  float: left;
  margin: 0.33em 2em 2em 0;
}

/* Tablet (portrait) ----------- */

// These styles kick in a little earlier when the sidebar appears

@include breakpoint(medium, $sidebar: true) {
  .topic-list {
    // suppress views column
    .views {
      display: none;
    }

    // reduce width for more title space
    .posts {
      width: 3em;
    }

    .posters {
      width: 2em;
      text-align: center;
    }

    // show only the first poster
    .topic-list-data.posters {
      a:first-child .avatar.latest:not(.single) {
        top: 0;
        left: 0;
      }

      a:not(.latest) {
        display: none;
      }

      a.latest {
        width: 100%;

        img {
          margin: 0 auto;
        }
      }
    }
  }
}

@include breakpoint(medium) {
  .nav-pills {
    > li > a {
      font-size: var(--font-0);
      padding: 0.45em 0.67em;
    }
  }

  .topic-list {
    .topic-list-data {
      font-size: var(--font-0);
    }
  }

  .topic-list-header {
    font-size: var(--font-down-1);
  }
}

.container.list-container {
  position: relative;
}
