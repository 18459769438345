.select-kit {
  &.dropdown-select-box {
    &.notifications-button {
      .select-kit-body {
        max-width: 400px;
      }

      .select-kit-row {
        .icons {
          align-self: flex-start;
          margin-right: 0.75em;
        }
      }
    }
  }
}
