.d-date-time-input {
  display: flex;
  border: 1px solid var(--primary-medium);
  box-sizing: border-box;
  position: relative;

  .date-picker {
    max-width: unset;
    border-color: transparent;
  }

  .d-date-time-input {
    margin-left: auto;
    flex: 1 1 auto;
  }

  .d-time-input {
    width: 90px;

    .select-kit-header {
      border-color: transparent;
      width: 90px;
    }
  }

  .d-date-input + .d-time-input {
    margin-left: 1px;
  }

  .d-time-input + .d-date-input {
    margin-right: 1px;
  }
}
