// --------------------------------------------------
// Navigation menus
// --------------------------------------------------

// Base
// --------------------------------------------------

%nav {
  margin-left: 0;
  list-style: none;

  li a {
    display: block;
    text-decoration: none;
  }
}

// Pill nav
// --------------------------------------------------
.nav-pills {
  @extend %nav;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 0.5em;

  > li {
    display: flex;

    > a,
    button {
      position: relative;
      border-radius: var(--d-nav-pill-border-radius);
      padding: 0.65em 0.75em;
      color: var(--primary);
      line-height: var(--line-height-small);
      box-sizing: border-box;
      min-height: 30px;
      display: flex;
      align-items: center;
      background-color: var(--d-nav-bg-color);
      transition: var(--d-button-transition);

      .d-icon {
        margin-right: 5px;
        color: var(--primary-high);
        transition: color 0.2s;
      }

      &:hover {
        color: var(--d-nav-color--hover);
        background: var(--d-nav-bg-color--hover);

        .d-icon {
          color: var(--d-nav-color--hover);
        }
      }
    }

    a.active,
    button.active {
      @include nav-active;
    }
  }
}

// Stacked nav
// --------------------------------------------------
.nav-stacked {
  @extend %nav;
  padding: 0;
  background: var(--primary-low);

  li {
    border-bottom: 1px solid var(--primary-low);

    &:last-of-type {
      border-bottom: 0;
    }

    &.indent {
      padding-left: 15px;
    }
  }

  a {
    margin: 0;
    padding: 0.75em;
    line-height: var(--line-height-small);
    cursor: pointer;
    color: var(--primary);
    @include ellipsis;

    &:hover {
      background: var(--d-sidebar-highlight-background);
    }

    &.active {
      position: relative;
      color: var(--primary);
      background-color: var(--d-selected);
      font-weight: bold;
    }
  }

  .count {
    font-size: var(--font-down-1);
  }

  .glyph {
    font-size: var(--font-down-1);
    width: 1.25em;
    text-align: center;
    margin-right: 0.25em;
    line-height: var(--line-height-large);
  }
}

.user-navigation .nav-pills > li {
  a,
  button {
    border-radius: 0;
  }
}
