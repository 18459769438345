// Common styles for "user-stream-item" component
.user-stream {
  // DEPRECATED:
  // The ".item" class should be removed because it's too generic.
  // Once ".item" has been removed, ".user-stream-item" can replace
  // ".user-stream" as the top-level selector on this file as all other
  // selectors below are its children
  .item,
  .user-stream-item {
    background: var(--d-content-background, var(--secondary));
    border-bottom: 1px solid var(--primary-low);
    padding: 1em 0.53em;
    list-style: none;

    &.moderator-action {
      background-color: var(--highlight-bg);
    }

    &.deleted {
      background-color: var(--danger-low-mid);
    }

    &.hidden {
      display: block;
      opacity: 0.4;
    }

    .post-list-item__header {
      display: flex;
      align-items: flex-start;
    }

    .post-list-item__details {
      flex-grow: 1;
      min-width: 0;

      .badge-category__wrapper {
        width: auto;
      }
    }

    .post-list-item__metadata {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5em;

      span + span {
        &::before {
          content: "•";
          margin-right: 0.25em;
          vertical-align: middle;
          color: var(--primary-medium);
        }
      }
    }
  }

  .draft-icon {
    color: var(--primary-high);
    font-size: var(--font-up-4);
    margin-right: 0.5rem;
    text-align: center;
  }

  .stream-topic-title {
    overflow-wrap: anywhere;
  }

  .draft-title {
    color: var(--tertiary);
    padding: 0;
  }

  .user-stream-item__metadata ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    color: var(--primary-medium);

    li {
      display: inline-flex;
      margin-left: 0.5em;
    }
  }

  .type,
  span.name {
    color: var(--primary);
  }

  .relative-date,
  .delete-info,
  .draft-type {
    line-height: var(--line-height-small);
    color: var(--primary-medium);
    font-size: var(--font-down-2);
    padding-top: unset !important;
    margin-right: 0.5rem;
  }

  .delete-info {
    align-items: center;
    display: flex;

    .d-icon {
      font-size: var(--font-0);
      margin-right: 0.5rem;
    }

    .avatar {
      margin-right: 0.25rem;
    }
  }

  .user-notifications-list {
    padding-top: 0;

    li.notification {
      padding: 0.25em 0;
      border-bottom: 1px solid var(--primary-low);

      a {
        align-items: center;
      }

      .d-icon {
        color: var(--primary-medium);
      }

      .relative-date {
        margin-left: auto;
        padding-top: 0;
        float: none;
      }
    }

    &:not(.show-avatars) {
      li.notification {
        padding: 0.75em 0;

        .d-icon {
          padding-top: 0;
          font-size: var(--font-up-2);
        }
      }
    }
  }

  .expand-item,
  .collapse-item {
    margin-right: 0.5em;
    margin-left: 0.25em;
    line-height: var(--line-height-small);
    padding-top: 3px;
    color: var(--primary-medium);
  }

  .avatar-link {
    margin-right: 0.5em;
  }

  .name {
    font-size: var(--font-0);
    max-width: 400px;
    @include ellipsis;
  }

  .edit-reason {
    background-color: var(--highlight-bg);
    padding: 3px 5px 5px 5px;
  }

  .excerpt {
    margin: 1em 0 0 0;
    font-size: var(--font-0);
    word-wrap: break-word;
    color: var(--primary);

    &:empty {
      display: none;
    }

    details.disabled {
      color: var(--primary-medium);
    }

    .emoji.only-emoji {
      // oversized emoji break excerpt layout
      // so we match inline emoji size
      width: 20px;
      height: 20px;
      margin: 0;
    }
  }

  .group-member-info {
    display: flex;
  }

  .user-stream-item .user-stream-item-draft-actions {
    display: flex;
    align-items: end;
    flex-direction: row;
    gap: 0.75em;
  }
}

.user-stream .child-actions, /* DEPRECATED: '.user-stream .child-actions' selector*/
.user-stream-item-actions,
.user-stream-item-draft-actions {
  margin-top: 8px;

  .avatar-link {
    float: none;
    margin-right: 3px;
  }

  .fa {
    width: 15px;
    display: inline-block;
    color: var(--primary);

    &.d-icon-heart {
      color: var(--love);
    }

    &.d-icon-trash-can {
      color: var(--secondary);
    }
  }
}
