.horizontal-overflow-nav {
  position: relative;
  min-width: 0;
  width: 100%;

  .nav-pills {
    overflow: auto;
    min-width: 0;
    position: relative;

    // avoids auto-scroll on initial load if active nav item is overflowed
    scroll-behavior: auto;

    // hides scrollbars, but allows mouse scrolling
    scrollbar-width: none;

    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &.has-scroll {
    .nav-pills {
      scroll-behavior: smooth; // smooth scrolling on user interaction
    }
  }
}

.horizontal-overflow-nav__scroll-right,
.horizontal-overflow-nav__scroll-left {
  --fade-width: 1.5em;
  --fade-color: var(--secondary-rgb); // must be RGB for gradient
  opacity: 1;
  position: absolute;
  z-index: 2;
  background-color: var(--secondary);
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  transition: opacity 0.25s;

  .d-icon {
    pointer-events: none;
    margin-bottom: 0.2em;
    color: var(--d-nav-color--active);
  }

  &.disabled {
    // hiding with opacity so we can transition visibility
    opacity: 0;
    pointer-events: none;
  }
}

.horizontal-overflow-nav__scroll-right {
  right: 0;

  &::before {
    content: "";
    margin-left: calc(var(--fade-width) * -1);
    height: 100%;
    width: var(--fade-width);
    background: linear-gradient(
      to left,
      rgba(var(--fade-color), 1),
      rgba(var(--fade-color), 0)
    );
  }
}

.horizontal-overflow-nav__scroll-left {
  left: 0;

  &::after {
    content: "";
    margin-right: calc(var(--fade-width) * -1);
    height: 100%;
    width: var(--fade-width);
    background: linear-gradient(
      to right,
      rgba(var(--fade-color), 1),
      rgba(var(--fade-color), 0)
    );
  }
}
