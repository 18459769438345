#topic-footer-buttons {
  display: flex;

  html.anon & {
    justify-content: end;
  }
  max-width: calc(
    var(--topic-avatar-width) + var(--topic-body-width) +
      var(--topic-body-width-padding) * 2
  );

  .topic-footer-main-buttons {
    display: flex;
    gap: 0.5rem;

    &__actions {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
    }

    .bookmark.bookmarked {
      .d-icon-discourse-bookmark-clock,
      .d-icon-bookmark {
        color: var(--tertiary);
      }
    }

    .create {
      align-self: flex-start;
    }
  }

  .pinned-button,
  .topic-notifications-button {
    .text {
      color: var(--primary-high);
    }
  }
}

.with-topic-progress {
  position: sticky;
  bottom: calc(env(safe-area-inset-bottom) + var(--composer-height, 0px));
  z-index: z("timeline");
  pointer-events: none; // the wrapper can block mobile controls

  #topic-progress-wrapper {
    pointer-events: auto; // this unsets the above rule so the element is interactive
  }
}

#topic-progress-wrapper {
  &.docked {
    .toggle-admin-menu {
      display: none;
    }
  }
  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  .btn {
    border: 0;
  }

  #topic-progress {
    position: relative;
    background-color: var(--secondary);
    color: var(--tertiary);
    padding: 0.5rem 1em;
    border: 1px solid var(--tertiary-low);

    /* as a big ol' click target, don't let text inside be selected */
    @include unselectable;

    .nums {
      display: flex;
      gap: 0.3rem;
      align-items: center;
      height: 100%;
      position: relative;
      z-index: z("base");
      font-size: var(--font-up-1);
      font-weight: bold;
    }

    .d-icon {
      position: absolute;
      right: 8px;
      bottom: 9px;
      z-index: z("base");
    }

    .bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: var(--progress-bg-width, 0);
      background-color: var(--tertiary-low);
    }
  }
}
