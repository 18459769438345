.d-header-wrap {
  @include sticky;
  top: 0;
  z-index: z("header");
}

.d-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: var(--header_background);
  box-shadow: var(--shadow-header);
  backface-visibility: hidden; /** do magic for scrolling performance **/

  > .wrap {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    .contents {
      display: flex;
      align-items: center;
      height: 100%;

      .header-row {
        width: 100%;

        .logo-wrapper {
          float: left;
        }

        .auth-buttons {
          float: right;
          margin-top: 0.4em;

          .login-button,
          .signup-button {
            padding: 8px 14px;
          }
        }
      }
    }
  }

  .title {
    --d-logo-height: 2.667em;
    display: flex;
    align-items: center;
    height: 100%;
    animation: fadein 0.5s;
    @media (prefers-reduced-motion) {
      animation-duration: 0s;
    }

    // min-width acts as a placeholder if the small logo takes a while to load
    // it prevents topic title from shifting after the small logo loads
    // it's set to match the #site-logo height so square small logos don't resize when titles dock
    min-width: var(--d-logo-height);

    a,
    a:visited {
      min-width: 0;
      color: var(--header_primary);
    }

    &:not(.title--minimized) {
      // allows large logos to be hidden if there are too many other header elements
      // this prioritizes nav elements, especially in cases of high zoom levels
      overflow: hidden;
    }
  }

  #site-logo {
    height: var(--d-logo-height);
    width: auto;
    max-width: 100%;
    object-fit: contain;
  }

  .home-logo-wrapper-outlet {
    overflow: hidden;
  }

  #site-text-logo {
    margin: 0;
    @include ellipsis;
  }

  .d-icon-house {
    font-size: var(--font-up-6);
  }

  .panel {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    margin-left: auto;
    align-items: center;
  }

  .hamburger-panel,
  .user-menu,
  .search-menu {
    width: 0; // Flexbox fix for Safari
  }

  .header-buttons {
    display: flex;
    align-items: center;
    margin-top: 0.2em;
    white-space: nowrap;

    .auth-buttons {
      display: flex;
    }
  }

  .login-button,
  .sign-up-button {
    padding: 6px 10px;

    .fa {
      margin-right: 3px;
    }
  }

  .login-button {
    margin-left: 7px;
  }
}

.header-dropdown-toggle,
.drop-down,
.panel-body {
  .flagged-posts,
  .reviewables {
    background: var(--danger);
    min-width: 6px;
  }
}

.d-header-icons {
  display: flex;
  margin: 0 0 0 0.5em;
  list-style: none;

  .icon {
    box-sizing: content-box;
    appearance: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.2857em;
    height: 2.2857em;
    padding: 0.2143em;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid transparent;
    outline: none;

    img.avatar {
      width: 2.1333em;
      height: 2.1333em;
    }

    .discourse-no-touch &:hover,
    .discourse-no-touch &:focus {
      background-color: var(--primary-low);
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;

      > .d-icon {
        color: var(--primary-medium);
      }
    }

    &:active {
      color: var(--primary);
      background-color: var(--primary-low);
    }
  }

  .drop-down-mode & {
    .active .icon {
      position: relative;
      background-color: var(--secondary);
      cursor: default;
      border-top: 1px solid var(--primary-low);
      border-left: 1px solid var(--primary-low);
      border-right: 1px solid var(--primary-low);

      > .d-icon {
        color: var(--primary-medium);
      }

      &::after {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: z("header") + 1; // Higher than .menu-panel
        width: 100%;
        height: 0;
        content: "";
        border-top: 1px solid var(--secondary);
      }

      &:hover {
        border-bottom: none;
      }
    }
  }

  .d-icon {
    width: 100%;
    font-size: var(--font-up-4);
    line-height: var(--line-height-large);
    display: inline-block;
    color: var(--header_primary-low-mid);
  }

  .notifications {
    position: relative;
  }

  .header-dropdown-toggle {
    position: relative;
  }

  .badge-notification {
    border: 2px solid var(--header_background);
    position: absolute;
    z-index: z("base");
    top: -4px;
    min-width: 0.6em;
    left: auto;
    right: -3px;

    &.with-icon {
      &.new-pms {
        background-color: var(--success);
      }

      &.new-reviewables {
        background-color: var(--danger);
      }

      .d-icon {
        color: var(--secondary);
        font-size: var(--font-down-1);
        width: 1em;
      }
    }
  }

  .unread-notifications {
    background-color: var(--tertiary-med-or-tertiary);
  }

  .unread-high-priority-notifications {
    left: auto;
    right: 25px;
  }
}

.header-sidebar-toggle {
  button {
    margin-right: 1em;
    box-sizing: content-box; // matches other header icons
    display: flex;
    justify-content: center;
    width: 2.2857em;
    height: 2.2857em;
    padding: 0.2143em;

    &:focus,
    .discourse-no-touch & {
      &:hover {
        background-color: var(--primary-low);
      }
    }

    .d-icon {
      width: 100%;
      font-size: var(--font-up-4);
      line-height: var(--line-height-large);
      display: inline-block;
      color: var(--header_primary-low-mid);
    }
  }
}

// topic info in the header
.extra-info-wrapper {
  flex: 1 0 0;
  display: flex;
  align-items: center;
  height: 100%;
  line-height: var(--line-height-medium);
  padding: 0 1.5em 0 0.5em;

  // we need to hide overflow in both to truncate the title in a flexbox
  overflow: hidden;

  .extra-info {
    overflow: hidden;
    width: 100%;
    animation: fadein 0.5s;
    @media (prefers-reduced-motion) {
      animation-duration: 0s;
    }
  }

  .title-wrapper {
    display: grid;
    grid-template-areas:
      "title title"
      "categories extra";
    grid-template-columns: auto minmax(2em, 1fr); // min must be as wide as ellipsis
    align-items: baseline;
    gap: 0 0.5em;

    .header-title {
      grid-area: title;
    }

    .categories-wrapper {
      grid-area: categories;
    }

    .topic-header-extra {
      grid-area: extra;

      .archetype-private_message & {
        grid-area: categories;
      }
    }
  }

  .topic-link {
    color: var(--header_primary);
    display: block;
    @include ellipsis;
  }

  .topic-statuses {
    .d-icon {
      color: var(--header_primary-medium);
    }

    .d-icon-envelope {
      color: var(--danger);
    }
  }

  .header-title {
    padding: 0;
    margin: 0;
    font-size: var(--font-up-3);
    width: 100%;
  }

  .categories-wrapper {
    display: inline-flex;
    flex: 0 1 auto;
    gap: 0 0.5em;
    @include ellipsis;
  }

  .badge-category__wrapper {
    min-width: 2.75em; // min needed for ellipsis
    @include ellipsis;

    .badge-category {
      color: var(--header_primary-high);
    }
  }

  .topic-header-extra {
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    gap: 0.5em;

    .discourse-tags {
      display: inline;
      color: var(--header_primary-high);
      @include ellipsis;

      .discourse-tag {
        display: inline; // tags need to stay inline in order for them to truncate
        vertical-align: unset;
      }
    }

    .topic-featured-link {
      align-self: baseline;

      .d-icon {
        font-size: var(--font-down-2);
      }
    }
  }
}

// PM header participants

$avatar-height: 1.641em;
$mobile-avatar-height: 1.532em;

.topic-header-participants {
  display: flex;
  align-items: center;
  font-size: var(--font-down-1);
  @include ellipsis;

  &:not(:first-child) {
    margin-left: 5px;
  }

  .trigger-user-card,
  .trigger-group-card {
    &:not(:last-of-type) {
      margin-right: 5px;
    }
  }

  .trigger-user-card {
    .icon {
      height: $avatar-height;

      .mobile-view & {
        height: $mobile-avatar-height;
      }
      display: inline-block;

      img {
        height: 100%;
        width: auto;
      }
    }
  }

  .trigger-group-card {
    padding: 0 5px;
    border: 1px solid var(--primary-low);
    border-radius: 0.25em;
    min-width: 3em;
    @include ellipsis;

    .icon {
      display: flex;
      align-items: center;
      height: $avatar-height;

      .mobile-view & {
        height: $mobile-avatar-height;
      }
      color: var(--primary-high);

      .d-icon {
        margin-right: 5px;
      }
    }

    span {
      @include ellipsis;
    }
  }

  .more-participants {
    color: var(--header_primary-high);
    margin-left: 5px;
  }
}

.d-header-mode {
  .bootstrap-mode {
    color: var(--primary-medium);
    font-size: var(--font-down-1);
    margin-left: 1em;
    padding: 0.15em 0.5em;

    &:focus {
      background-color: var(--primary-medium);
      color: var(--secondary);
    }
  }

  .fk-d-button-tooltip .fk-d-tooltip__trigger {
    color: var(--header_primary-high);
    background: transparent;
  }
}

#additional-panel-wrapper {
  position: absolute;

  // positions are relative to the .d-header .panel div
  top: 100%; // directly underneath .panel
  right: -10px; // 10px to the right of .panel - adjust as needed
  max-height: 80vh;
  border-radius: var(--d-border-radius-large);
  overflow: auto;
}
