.topic-list-item td:first-of-type,
.topic-post {
  border-left: 1px solid transparent;
}

.topic-list tr.selected td:first-of-type,
.topic-list-item.selected td:first-of-type,
.latest-topic-list-item.selected,
.search-results .fps-result.selected {
  box-shadow: inset 3px 0 0 var(--d-nav-color--active);
}

.featured-topic.selected,
.topic-post.selected {
  box-shadow: -3px 0 0 var(--d-nav-color--active);
}

.topic-list tr.selected,
.topic-list-item.selected,
.featured-topic.selected,
.topic-post.selected,
.latest-topic-list-item.selected,
.search-results .fps-result.selected {
  &:focus-visible {
    outline: none;
  }
}

.latest .featured-topic {
  padding-left: 4px;
}

.topic-list-item.selected {
  background-color: inherit;
}

.keyboard-shortcuts-modal.d-modal.-max {
  .d-modal__container {
    max-width: 70vw;
  }
}

.shortcut-category {
  border: 1px solid var(--primary-low);

  h2 {
    font-size: var(--font-0);
    background-color: var(--primary-very-low);
    padding: 0.5rem 1rem;
  }
}

#keyboard-shortcuts-help {
  box-sizing: border-box;

  .keyboard-shortcuts-help__container {
    columns: 2 20em; // Set maximum of 2 columns

    section {
      display: inline-block;
      margin-bottom: 1.5em;
      width: 100%;
    }
  }

  &:focus {
    outline: none;
  }

  kbd:first-child {
    margin-left: 0;
  }

  @for $i from 1 through 25 {
    .span-#{$i} {
      grid-row-end: span $i;
    }
  }

  ul {
    list-style: none;
    margin: 0;

    li {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row-reverse;
      justify-content: space-between;
      border-bottom: 1px solid var(--primary-low);
      padding: 0.25rem 1rem;
    }

    span {
      border-radius: 3px;
      display: inline-flex;
      margin: 0 6px;
      padding: 2px 1px 4px;
    }

    span:first-child {
      margin-right: 0;
    }

    span:last-child {
      margin-left: auto;
    }

    .delimiter-newline {
      display: revert;
      text-align: right;
    }

    kbd {
      font-family: var(--font-family);
      font-weight: bold;
    }
  }
}
