:root {
  --base-font-size-smallest: 0.815em; // eq. to 13px
  --base-font-size-smaller: 0.875em; // eq. to 14px
  --base-font-size: 1em; // eq. to 16px
  --base-font-size-larger: 1.125em; // eq. to 18px
  --base-font-size-largest: 1.25em; // eq. to 20px

  // Font-size definitions, multiplier ^ (step / interval)
  --font-up-6: 2.296em;
  --font-up-5: 2em;
  --font-up-4: 1.7511em;
  --font-up-3: 1.5157em;
  --font-up-2: 1.3195em;
  --font-up-1: 1.1487em; // 2^(1/5)
  --font-0: 1em;
  --font-down-1: 0.8706em; // 2^(-1/5)
  --font-down-2: 0.7579em; // Smallest size we use based on the 1em base
  --font-down-3: 0.6599em;
  --font-down-4: 0.5745em;
  --font-down-5: 0.5em;
  --font-down-6: 0.4355em;

  // Font-size definitions in rem used in cooked headings
  --font-up-3-rem: 1.5157rem;
  --font-up-2-rem: 1.3195rem;
  --font-up-1-rem: 1.1487rem;
  --font-0-rem: 1rem;
  --font-down-1-rem: 0.8706rem;
  --font-down-2-rem: 0.7579rem;

  // Common line-heights
  --line-height-small: 1;
  --line-height-medium: 1.2; // Headings or large text
  --line-height-large: 1.4; // Normal or small text
}
