.d-stat-tiles {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1em;
  margin-bottom: 2em;

  .d-stat-tile {
    display: flex;
    flex-direction: column;
    padding: 1em;
    background: var(--primary-very-low);
    border-radius: 0.25em;

    &__label {
      color: var(--primary-medium);
      font-size: 0.875em;
      margin-bottom: 0.5em;
    }

    &__value {
      color: var(--primary);
      font-size: 1.5em;
      font-weight: bold;
    }
  }
}
