.d-select {
  width: 100%;
  height: 2.25em;
  background: var(--secondary);
  border: 1px solid var(--primary-400);
  border-radius: var(--d-input-border-radius);
  box-sizing: border-box;
  margin: 0;
  padding: 0 2em 0 0.5em !important;
  appearance: none;
  background-image: svg-uri(
    "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$primary-medium}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m2 5 6 6 6-6'/></svg>"
  );
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 16px 12px;
  cursor: pointer;

  &:focus,
  &:focus-visible,
  &:focus:focus-visible,
  &:active {
    //these importants are another great case for having a button element without that pesky default styling
    &:not(:disabled) {
      background-color: var(--secondary) !important;
      color: var(--primary) !important;
      border-color: var(--tertiary);
      outline: 2px solid var(--tertiary);
      outline-offset: -2px;

      .d-icon {
        color: inherit !important;
      }
    }
  }

  &:hover:not(:disabled) {
    .discourse-no-touch & {
      background-color: var(--secondary);
      color: var(--primary);
      border-color: var(--tertiary);

      .d-icon {
        color: inherit;
      }
    }
  }
}
