// styles used for JSON schema settings
.d-modal.json-schema-editor-modal {
  --modal-max-width: 95vw;
  --modal-width: unset;
  --space-1: 0.25em;
  --space-2: calc(0.25em * 2);
  --space-3: calc(0.25em * 3);
  --space-4: calc(0.25em * 4);
  --space-5: calc(0.25em * 5);
  --space-6: calc(0.25em * 6);

  .d-modal__container {
    width: unset;
  }

  .je-ready {
    .je-object__container {
      padding: 0;
      border: none;
      margin-bottom: var(--space-4);

      > span:not(.je-object__controls) {
        display: flex;
        padding: var(--space-1) 0 var(--space-4) var(--space-6);

        .json-editor-btn-delete {
          order: 2;
          margin-left: auto;
          margin-right: 0;
        }
      }
    }

    .je-object__controls {
      margin: 0;
    }

    .json-editor-btn-delete {
      @extend .btn-danger;
      @extend .no-text;
    }

    .json-editor-btn-add {
      @extend .btn, .ok;
      gap: var(--space-1);

      span {
        display: inline;
      }
    }

    button {
      @extend .btn;
      @extend .no-text;

      span {
        display: none;
      }
    }

    .json-editor-btn-collapse {
      @extend .btn-flat;
    }

    [class*="json-editor-btn"] {
      margin-right: var(--space-2);
    }

    select {
      @include appearance-none;
      @include form-item-sizing;
      display: inline-block;
      margin-bottom: var(--space-2);
      color: var(--primary);
      background-color: var(--secondary);
      border: 1px solid var(--primary-400);
      border-radius: var(--d-input-border-radius);
      background: svg-uri(
        '<svg xmlns="http://www.w3.org/2000/svg" fill="#999" height=".75em" viewBox="0 0 448 512"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"/></svg>'
      ); // down chevron
      background-repeat: no-repeat;
      background-position: 98%;

      &:focus {
        @include default-focus;
      }
    }

    select,
    input {
      width: 100%;
      margin: 0;
    }

    .row:not(:first-child) {
      .form-control {
        margin: var(--space-4) 0;
      }
    }

    .form-control {
      p {
        // description
        color: var(--primary-medium);
        margin-top: var(--space-1);
        font-size: var(--font-down-1);
      }
    }

    h3 {
      display: flex;
      align-items: center;
      margin: 0;

      button.json-editor-btn-collapse.json-editor-btntype-toggle {
        // needs the specificity
        background: transparent;
        margin: 0 0 0 calc(var(--space-2) * -1);
        padding: var(--space-1) var(--space-2);
        font-size: var(--font-down-2);

        .d-icon {
          color: var(--primary-medium);
        }
      }

      label {
        margin: 0 0 0 0.1em; // minor visual alignment
        font-weight: normal;
        font-size: var(--font-up-1);
        color: var(--primary);
      }
    }

    .je-indented-panel {
      margin: 0;
      padding: var(--space-4) 0 0 var(--space-6);
      border-left: none;
    }

    [data-schemaid="root"] {
      position: relative;

      > .je-header {
        label,
        .json-editor-btn-collapse {
          display: none;
        }

        button {
          position: absolute;
          right: 0;
          margin-right: 0;
          font-size: var(--font-down-1);
          z-index: 2;
        }
      }

      > .je-indented-panel {
        margin: 0;
        padding: 0;
        border: none;
      }
    }

    // for "format":"tabs-top"
    .je-tabholder--top {
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      gap: 0 var(--space-3);
      border-bottom: 1px solid var(--primary-low);
      padding-bottom: var(--space-1);

      .je-tab--top {
        border: none;
        padding: 0;
        color: var(--primary);
        background: var(
          --secondary
        ) !important; // important is needed here to override inline styling on the element
      }
    }

    .je-tabholder--clear {
      padding-top: var(--space-3);

      .je-object__title {
        display: none;
      }

      .je-object__container > span:not(.je-object__controls) {
        padding: 0;
      }

      h3 label {
        margin: 0;
      }

      .je-indented-panel--top {
        margin: 0;
        padding: 0;
      }

      .json-editor-btn-collapse {
        display: none;
      }

      .je-indented-panel {
        padding-left: 0;
      }
    }

    // for "format":"table"
    table {
      width: 100%;
      margin-bottom: 1em;

      td,
      th {
        &:not(:first-child, :last-child) {
          padding: var(--space-2);
        }

        &:first-child,
        &:last-child {
          padding: var(--space-2) 0;
        }
      }

      td {
        vertical-align: top;

        select {
          background-position: center right var(--space-2);
          padding-right: var(--space-6);
        }

        &:last-child {
          span {
            display: flex;
            flex-direction: column;
            gap: var(--space-1);

            button {
              margin: 0;
            }

            .delete {
              order: 2;
            }
          }
        }
      }
    }

    div[data-schematype="boolean"] {
      label {
        display: flex;
        align-items: center;

        input[type="checkbox"] {
          margin-right: var(--space-1);
        }
      }
    }

    select option {
      color: var(--primary);
      background: var(--secondary);
    }
  }
}
