.select-kit {
  &.dropdown-select-box {
    &.toolbar-popup-menu-options {
      .select-kit-collection {
        max-height: 35vh;
      }

      .select-kit-body {
        box-shadow: none;
        border-radius: 0;
      }

      .select-kit-row {
        padding: 0.65em 0.5em;
        border-bottom: 1px solid rgba(var(--primary-low-rgb), 0.5);
        display: flex;
        align-items: center;

        // popup-menu doesnt use description text atm
        // it's just easier to align the icon with text then
        .icons {
          align-self: center;
        }

        .shortcut {
          margin-left: 1em;
        }

        &:last-child {
          border: none;
        }

        .texts .name {
          display: flex;
          align-items: center;
        }

        .texts .name,
        .icons .d-icon {
          font-size: var(--font-0);
          font-weight: normal;
        }

        .d-icon {
          color: var(--primary-medium);
        }

        .shortcut {
          border: none;
          background: var(--primary-200);
          font-size: var(--font-down-2);
          color: var(--primary-high);
          margin-left: 1.8rem;
        }

        &.is-highlighted,
        &.is-selected,
        &:hover {
          background: var(--tertiary-low);
          color: var(--primary);
        }
      }
    }
  }
}
