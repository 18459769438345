.alert {
  padding: 0.5em 2.5em 0.5em 1em;
  background-color: var(--danger-low);
  color: var(--primary);
  position: relative;
  margin-bottom: 1em;

  .close {
    font-size: var(--font-up-3);
    position: absolute;
    top: 0.265em;
    right: 0.66em;
    padding: 0;

    .d-icon {
      color: var(--primary-medium);
    }
  }

  &.alert-success {
    background-color: var(--success-low);
    color: var(--primary);
  }

  &.alert-error {
    background-color: var(--danger-low);
    color: var(--primary);
  }

  &.alert-warning {
    background-color: var(--highlight-bg);
    color: var(--primary);
  }

  &.alert-info {
    background-color: var(--tertiary-low);
    color: var(--primary);

    &.clickable {
      color: var(--tertiary);
      z-index: z("base");
    }
  }

  &.-top-margin {
    margin-top: 1em;
  }
}

a.alert.clickable {
  display: flex;
}
