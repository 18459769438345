.form-kit__col {
  flex: 0 0 auto;
  box-sizing: border-box;
}

@include breakpoint("large") {
  .--col-1,
  .--col-2,
  .--col-3,
  .--col-4,
  .--col-5,
  .--col-6,
  .--col-7,
  .--col-8,
  .--col-9,
  .--col-10,
  .--col-11,
  .--col-12 {
    width: 100% !important;
  }
}

.--col-1 {
  width: 8.33333333%;
}

.--col-2 {
  width: 16.66666667%;
}

.--col-3 {
  width: 25%;
}

.--col-4 {
  width: 33.33333333%;
}

.--col-5 {
  width: 41.66666667%;
}

.--col-6 {
  width: 50%;
}

.--col-7 {
  width: 58.33333333%;
}

.--col-8 {
  width: 66.66666667%;
}

.--col-9 {
  width: 75%;
}

.--col-10 {
  width: 83.33333333%;
}

.--col-11 {
  width: 91.66666667%;
}

.--col-12 {
  width: 100%;
}
