.d-image-grid:not([data-disabled]) {
  $grid-column-gap: 6px;

  &[data-columns] {
    display: flex;
    flex-wrap: wrap;
  }

  &[data-columns="2"] > * {
    flex-basis: calc(50% - ($grid-column-gap / 2));
    margin-right: $grid-column-gap;
  }

  &[data-columns="3"] > * {
    flex-basis: calc(33.33% - ($grid-column-gap * 0.667));
    margin-right: $grid-column-gap;
  }

  .d-image-grid-column {
    box-sizing: border-box;

    // use flex layout, flex-grow and object-fit: cover
    // to better have the images fill their containers
    // and line up vertically (it's not perfect!)
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .button-wrapper {
      bottom: 0;
      min-width: unset;
    }

    > div,
    > span {
      flex-grow: 1;
      overflow: hidden;

      // hardcoded max-height here prevents extra tall images
      // from having an outsized effect on the grid
      max-height: 1200px;

      img:not(.thumbnail, .ytp-thumbnail-image, .emoji) {
        height: 100%;
        object-fit: cover;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    > img,
    > div {
      margin-bottom: $grid-column-gap;
    }

    // Forces images in the grid to fill each column width-wise
    img,
    > .lightbox-wrapper,
    > .lightbox-wrapper > .lightbox {
      width: 100%;
    }

    .lightbox-wrapper {
      .meta .informations {
        display: none;
      }

      .meta .filename {
        flex-grow: 3;
      }

      // full-height lightbox element in container
      > .lightbox {
        height: 100%;
      }
    }

    // when staging edits
    .image-wrapper {
      display: block;
      margin-bottom: $grid-column-gap;
      padding-bottom: 0;
    }
  }

  .d-editor-preview & {
    .image-wrapper {
      margin-bottom: $grid-column-gap;
      padding-bottom: 0;

      .button-wrapper {
        .scale-btn-container,
        &[editing] .wrap-image-grid-button {
          display: none;
        }
      }
    }
  }
}
