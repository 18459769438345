div.ac-wrap.composer-user-selector-limited {
  width: 400px;
  padding: 0 10px;
  margin-bottom: 5px;

  .btn-primary {
    margin-left: 0.5em;
    padding: 3px 6px 2px;
    font-size: var(--font-down-1);
    min-height: unset;
  }
}
