.filter-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--primary-medium);
  box-sizing: border-box;

  &.is-focused {
    border: 1px solid var(--tertiary);
  }

  .filter-input,
  .filter-input:focus {
    width: 100%;
    margin: 0;
    border: none;
    outline: none;

    @include breakpoint(tablet, min-width) {
      .chat-browse-view__actions & {
        width: 210px;
      }
    }
  }

  .d-icon {
    color: var(--primary-medium);
    margin: 0 0.5rem;
  }
}
