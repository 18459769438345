.color-picker {
  display: flex;
  align-items: stretch;

  .add-on {
    @include form-item-sizing;
    background-color: var(--primary-low);
    border-color: var(--primary-medium);
    border-right-color: transparent;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .hex-input {
    margin: 0;
  }

  .picker {
    padding: 0;
    border-left: none;
    cursor: pointer;
    margin-bottom: 0;
    height: unset;
  }

  // Reset webkit/blink default style
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius: 0; // Reset webkit specific style
  }
}
