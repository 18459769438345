$mobile-breakpoint: 700px;

.d-page-header,
.d-page-subheader {
  &__title-row {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: var(--space-2);

    h1,
    h2 {
      margin: 0;
    }

    h2 {
      font-size: var(--font-up-2);
    }

    .d-page-header__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media (max-width: $mobile-breakpoint) {
        flex-direction: column;
      }

      button {
        margin-left: var(--space-2);

        @media (max-width: $mobile-breakpoint) {
          width: 100%;
          margin-bottom: var(--space-2);
          margin-left: 0;
        }
      }
    }
  }

  .d-nav-submenu {
    background: transparent;
    border-bottom: 1px solid var(--primary-low);

    .horizontal-overflow-nav {
      background: transparent;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }

    .nav-pills {
      width: auto;
      margin: 0;
    }
  }
}

.d-page-header {
  &__title-row {
    @media (max-width: $mobile-breakpoint) {
      flex-direction: row;
      align-items: center;

      .d-page-header__actions {
        button {
          margin-bottom: 0;
        }
      }
    }
  }
}

.d-page-subheader {
  &__title-row {
    @media (max-width: $mobile-breakpoint) {
      flex-direction: row;
      align-items: center;
    }
  }
}

.d-page-action-list-item {
  .btn-primary {
    color: var(--primary);
  }
}
