.custom-date-time-wrap {
  padding: 1em 1em 0.5em;
  border: 1px solid var(--primary-low);
  border-top: none;
  background: var(--primary-very-low);

  .d-icon {
    padding: 0 0.75em 0 0;
    color: var(--primary-high);
    margin-top: -0.5em;
  }

  .caret-icon {
    margin-top: 0;
    padding: 0 0 0 5px;
  }

  .tap-tile-date-input,
  .tap-tile-time-input {
    display: flex;
    align-items: center;
  }

  .date-picker,
  .time-input {
    text-align: left;
  }

  .time-input,
  .date-picker-wrapper {
    flex: 1 1 auto;
  }

  &.custom-input-wrap {
    border-bottom: 0;
    margin-bottom: 0;
  }

  &.custom-relative-wrap {
    .relative-time-picker {
      display: flex;
    }
  }
}
