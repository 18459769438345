// We should try to reuse the user page HTML for groups when possible
// To style group content differently, use the existing classes with a .group parent class.
// For example: .group .user-secondary-navigation

@use "sass:math";

.container.group {
  background: var(--d-content-background);
}

.group-details-container {
  background: var(--primary-very-low);
  padding: 20px;
  margin-bottom: 15px;
  position: relative;
}

.group-delete-tooltip {
  background-color: var(--tertiary-low);
  padding: 0.1em 2.5em 0.1em 1em;
  margin-bottom: 10px;
}

.group-outlet {
  position: relative;
}

.group-members-actions {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.5em 0;

  .bulk-select + input {
    margin-left: 0.5em;
  }

  input {
    margin: 0 auto 0 0;
  }

  .bulk-select-buttons-wrap {
    margin-right: 0.5em;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
  }
}

.group-members-manage {
  display: flex;
  gap: 0.5em;
}

.group-info {
  align-items: flex-start;
  display: flex;
  gap: 10px;

  .group-info-names {
    flex-grow: 1;
  }

  .group-info-name {
    font-size: var(--font-up-3);
    font-weight: bold;
    color: var(--primary);
  }

  .group-info-full-name {
    font-size: var(--font-up-2);
    color: var(--primary-high-or-secondary-low);
  }

  $size: 50px;
  $icon-size: math.div($size, 1.8);

  .avatar-flair-image {
    width: $size;
  }

  .avatar-flair {
    background-size: $size;
    height: $size;
    width: $size;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;

    .d-icon {
      height: $icon-size;
      width: $icon-size;
    }
  }

  .group-details-button {
    display: flex;
    flex-wrap: wrap;

    button:not(:last-child) {
      margin-right: 0.5em;
    }
  }
}

.group-nav {
  .count {
    margin-left: 5px;
  }
}

.group-manage-logs-filter {
  margin-right: 10px;

  &:hover {
    background-color: var(--danger);
  }
}

table.group-manage-logs {
  width: 100%;

  td {
    padding: 0.5em;
  }

  .group-manage-logs-expand-details {
    cursor: pointer;

    .d-icon {
      color: var(--primary-medium);
    }
  }
}

.group-members {
  grid-template-columns: 3fr repeat(3, minmax(min-content, 1fr));

  &--can-manage {
    grid-template-columns: 3fr repeat(4, minmax(min-content, 1fr)) 3em;
    @container (max-width: 47em) {
      // positioning the member settings button within the same cell
      // and avoiding overlap with padding-right on user-info
      .group-member,
      .member-settings {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-column-end: -1;
      }

      .member-settings {
        margin-left: auto;
      }

      .user-info {
        padding-right: 3.5em;
      }
    }
  }

  &.group-members__requests {
    grid-template-columns: 3fr repeat(3, minmax(max-content, 1fr));
  }

  .directory-table__value {
    font-size: var(--font-0);
    color: var(--primary);
  }

  .group-accept-deny-buttons {
    gap: 0.5em;
  }

  [class*="group-request-reason__"] {
    justify-content: start;
  }

  .group-request-reason__content {
    .directory-table__value {
      white-space: normal;
      max-width: 30em;
    }
  }

  @container (max-width: 47em) {
    .directory-table__cell {
      grid-column-start: 1;
      grid-column-end: -1;
    }

    .group-accept-deny-buttons {
      justify-content: start;
    }

    .directory-table__cell.group-owner {
      order: 2;
    }
  }
}

.group-owner-label {
  color: var(--primary);
}

.group-flair-inputs {
  display: inline-block;

  .avatar-flair-preview {
    position: relative;
    width: 45px;

    .avatar-wrapper {
      background-color: var(--primary-very-low);
    }
  }

  .radios {
    margin-bottom: 5px;
  }

  .radio-label {
    display: inline-flex;
    padding-right: 10px;
    margin-bottom: 0;
    align-items: center;
  }

  .uploaded-image-preview {
    height: 75px;
    width: 275px;
  }
}

.group-form-save {
  margin-right: 20px;
}

.group-add-members {
  .ac-wrap {
    overflow: hidden;
  }

  .group-add-members-make-owner {
    label {
      display: inline;
      vertical-align: middle;
    }

    input[type="checkbox"] {
      vertical-align: middle;
    }
  }
}

label.group-category-permissions-desc {
  font-size: 1.15em;
  margin-bottom: 1em;
}

table.group-category-permissions {
  width: 100%;

  tr {
    line-height: 3em;
    width: 100%;

    .category-name {
      font-size: 1.25em;
    }
  }
}

.group-smtp-prefill-options {
  ul {
    display: inline;
    margin: 0;

    li {
      display: inline-block;

      &::before {
        content: "|";
      }

      &:first-child::before {
        content: "";
      }
    }
  }
}

.group-smtp-email-settings,
.group-imap-email-settings {
  background-color: var(--primary-very-low);
  padding: 1em;
  margin: 1em 0;
  border: 1px solid var(--primary-low);

  .groups-form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(13em, 1fr));
    margin-bottom: 0;
    gap: 0 1em;

    details,
    .ember-text-field {
      width: 100%;
    }
  }
}

.group-manage-email-additional-settings-wrapper {
  margin-top: 1em;
}

.groups-form .control-group.buttons {
  margin-bottom: 0;
  padding: 1em 0;
}
