.form-kit__field {
  .form-kit__container-content {
    align-items: flex-start;
    flex-direction: column;
    width: var(--form-kit-medium-input);
  }

  &.--full {
    width: 100%;
  }

  &-textarea,
  &-composer,
  &-code,
  &-image {
    .form-kit__container-content {
      width: var(--form-kit-large-input) !important;
    }
  }

  &-toggle {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: var(--form-kit-medium-input);

    .form-kit__container-content {
      align-items: flex-end;
    }

    .form-kit__container-title {
      padding: 0;
    }
  }

  .form-kit__container-description {
    margin: 0;
    font-size: var(--font-down-1-rem);
  }

  .form-kit__container-title,
  .form-kit__container-description,
  .form-kit__container-content {
    &.--small {
      width: var(--form-kit-small-input) !important;
    }

    &.--medium {
      width: var(--form-kit-medium-input);
    }

    &.--large {
      width: var(--form-kit-large-input);
    }

    &.--full {
      width: 100% !important;
    }
  }
}
