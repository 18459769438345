.more-topics__container {
  .nav {
    position: absolute;

    li {
      margin-right: 0;

      .btn {
        font-size: var(--font-0);
        line-height: var(--line-height-large);
        padding: 0.75em 0.65em;
      }
    }
  }

  .more-topics__lists:not(.single-list) {
    .topic-list-header .default {
      visibility: hidden;
    }
  }
}
