.form-kit__field-custom {
  width: auto !important;
  min-width: var(--form-kit-small-input);

  .form-kit__control-custom {
    width: 100%;
  }

  .form-kit__control-custom > * {
    width: 100% !important;
  }
}
