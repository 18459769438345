.offline-indicator {
  position: fixed;
  top: var(--header-offset);
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0.5em 1em;
  background-color: var(--primary-very-low);
  border-radius: var(--d-nav-pill-border-radius);
  z-index: z("header") - 10;
}
