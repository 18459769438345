@use "sass:math";

.groups-header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;

  .groups-header-new {
    order: 2;
    margin-bottom: 0.5em;
  }
}

.groups-header-filters {
  display: flex;
  flex-wrap: wrap;

  .groups-header-filters-name,
  .groups-header-filters-type {
    margin: 0 0.5em 0.5em 0;
  }

  &:last-child {
    margin-right: auto;
  }

  .select-kit-header {
    height: 100%;
  }
}

.container.groups-index {
  background: var(--d-content-background);
}

.groups-boxes {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(16em, 1fr));
  margin: 1em 0;
  width: 100%;

  .group-box {
    display: flex;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid var(--primary-low);
    color: var(--primary);

    .discourse-no-touch & {
      &:hover {
        box-shadow: var(--shadow-card);
        border-color: var(--primary-low-mid-or-secondary-high);
      }
    }

    .group-membership {
      color: var(--primary-medium);
      margin-top: auto;
      padding-top: 1em;

      .is-group-owner,
      .is-group-member {
        color: var(--success);
      }
    }

    .group-box-inner {
      padding: 1em;
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      min-height: 8em;

      .group-info-wrapper {
        align-items: center;
        display: flex;
        flex: 0 1 auto;
        margin-bottom: 0.25em;
        min-height: 40px;
        overflow: hidden;

        .group-avatar-flair {
          margin-right: 0.5em;
        }

        .group-info {
          flex: 1 1 auto;

          span {
            width: 100%;
            word-break: break-word;
            line-height: var(--line-height-medium);
          }
        }
      }

      .group-user-count {
        display: flex;
        align-items: center;
        color: var(--primary-high);

        .d-icon {
          margin-right: 0.25em;
          font-size: 0.8em;
          color: var(--primary-medium);
        }
      }

      .group-description {
        color: var(--primary-high);
        word-wrap: break-word;
      }
    }

    $size: 40px;
    $icon-size: math.div($size, 1.8);

    .group-avatar-flair {
      display: inline-block;
      color: var(--primary);

      .avatar-flair {
        background-size: $size;
        height: $size;
        width: $size;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;

        .d-icon {
          height: $icon-size;
          width: $icon-size;
        }
      }
    }

    .avatar-flair-image {
      width: $size;
    }
  }
}

.groups-form {
  .control-group-inline {
    display: inline;
  }

  &.groups-notifications-form {
    max-width: 33em;

    .control-instructions {
      color: var(--primary-medium);
      margin-bottom: 10px;
      font-size: var(--font-down-1);
      line-height: var(--line-height-large);
    }

    .category-selector,
    .tag-chooser {
      width: 100%;
    }
  }

  .groups-form-incoming-email {
    margin-bottom: 1em;
  }
}

.group-manage-logs-controls {
  button {
    .d-icon {
      // flip the icon order for the remove button
      order: 2;
      margin: 0 0 0 0.45em;
    }
  }
}

.group-automatic-tooltip {
  align-items: center;
  font-size: var(--font-down-2);
  padding: 0.2em 1em;
  background-color: var(--primary-low);
  border-radius: var(--d-button-border-radius);

  .fk-d-tooltip__trigger-container {
    align-items: center;

    .d-icon {
      margin-right: 0.25em;
    }
  }
}
