.directory-table-top-scroll {
  width: 100%;
  overflow-x: auto;
}

.directory {
  .directory-value-list-item:not(:empty)
    ~ .directory-value-list-item:not(:empty)::before {
    content: "| ";
  }
  margin-bottom: 100px;
  background: var(--d-content-background);

  &.users-directory {
    .directory-group-selector {
      vertical-align: top;
    }
  }

  .user-info {
    margin-bottom: 0;
  }

  .spinner {
    clear: both;
  }

  .directory-controls {
    display: flex;
    justify-content: space-between;

    .total-rows {
      color: var(--primary-medium);
      font-weight: normal;
    }
  }

  .directory-last-updated {
    margin-bottom: 0.5em;
    color: var(--primary-medium);
    font-size: var(--font-down-1);
  }
}

.edit-user-directory-columns-modal {
  .edit-directory-columns-container {
    .edit-directory-column {
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      border-bottom: 1px solid var(--primary-low);

      .column-name {
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }

      .d-icon-heart {
        color: var(--love);
        margin: 0 0.25em 0 0;
      }

      .move-column-up {
        margin-right: 5px;
      }

      .left-content,
      .right-content {
        display: flex;
        align-items: center;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  .d-modal__footer {
    justify-content: space-between;
  }
}

@container (min-width: 47em) {
  .users-directory {
    .directory-table {
      &__value {
        white-space: nowrap;
        font-size: var(--font-up-2);

        &,
        &--user-field {
          color: var(--primary-medium);
        }
      }
    }
  }
}

.directory-table-container {
  container-type: inline-size;
  container-name: directory-table;
  transform: translateX(0);

  // transform here creates a containing blocks which
  // is used by fixed-positioned dropdowns
  // if omitted, `overflow-x: auto;` below will clip them
}

.directory-table {
  display: grid;
  gap: 0;
  width: 100%;
  margin-top: 1em;
  overflow-x: auto;

  .me {
    .directory-table__cell {
      &,
      &--user-field {
        background-color: var(--highlight-low-or-medium);
      }
    }
  }

  &__header,
  &__body,
  &__row {
    display: contents;

    @supports (grid-template-rows: subgrid) {
      display: grid;
      grid-column: 1 / -1;
      grid-template-columns: subgrid;
    }
  }

  &__column-header,
  &__cell,
  &__cell--empty,
  &__cell--user-field {
    display: flex;
    border-bottom: 1px solid var(--primary-low);
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  &__column-header {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    color: var(--primary-medium);
    padding: 0.5em;

    .d-icon {
      margin-right: 0.25em;
    }

    &:first-child {
      .header-contents {
        text-align: left;
      }
    }
  }

  &__cell {
    &,
    &--user-field {
      padding: 0.75em 0.5em;
    }
  }

  &__value {
    white-space: nowrap;

    &--user-field {
      max-width: 30em;
    }
  }

  &__label {
    display: none;
  }

  .d-icon-heart {
    font-size: var(--font-down-1);
    color: var(--love);
  }

  .user-detail {
    display: flex;
    flex-direction: column;
    min-width: 0; // allow content to shrink and hide overflow
  }

  .user-info {
    display: flex;
    min-width: 0;
    width: 100%;

    .user-detail {
      padding: 0;
      width: 100%;
      @include breakpoint(tablet) {
        // overrides existing media query
        font-size: var(--font-0);
      }
      @include breakpoint("mobile-medium") {
        font-size: var(--font-down-1);
      }
    }

    .title {
      margin: 0;
    }
  }

  .header-contents {
    width: 100%;
    text-align: center;
  }
}

// using a container query to switch to a flex-based layout
// browsers without support for container queries
// fallback to big horizontal scrolling table

@container (max-width: 47em) {
  .directory-table {
    display: flex;
    flex-direction: column;

    .me {
      background-color: var(--highlight-low-or-medium);
    }

    &__label {
      display: inline-flex;
      color: var(--primary-medium);
      padding-right: 0.5em;
      align-items: baseline;
      align-self: start;
      white-space: nowrap;
      overflow: hidden;
      flex: 1 0 calc(50% - 0.5em + 5%); // 50% - padding + half the gap

      span {
        // caution: display flex here can interfere with overflow hiding
        flex: 0 1 auto; // can shrink if needed
        margin-right: 0.25em;
        @include ellipsis;
      }

      // flexible divider between the label and value
      &::after {
        flex: 1 1 0; // can grow or shrink, but should be 0 width if needed
        color: var(--primary-300);
        min-width: 0;
        overflow: hidden;

        // this needs to be long to account for all possible widths
        content: "................................................................................................................................................................";
      }

      .d-icon {
        font-size: 0.8em;
        vertical-align: baseline;
      }
    }

    &__value {
      font-size: var(--font-0);
      color: var(--primary);
      align-self: start;

      &--user-field {
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
      }
    }

    &__row {
      &:first-child {
        border-top: 1px solid var(--primary-low);
      }
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(11em, 1fr));
      border-bottom: 1px solid var(--primary-low);
      padding: 0.85em 0.5em 1em;
      gap: 0 10%;
    }

    &__header {
      display: none;
    }

    &__cell {
      &,
      &--user-field {
        padding: 0.25em;
        border: none;

        &:first-child {
          width: 100%;
          padding: 0.5em 0.25em 1em;
          justify-content: start;

          // force full width of the cell
          grid-column-start: 1;
          grid-column-end: -1;
        }
      }

      &--user-field {
        display: flex;
        order: 2;

        // force full width of the cell
        // because we don't know how much content there is
        grid-column-start: 1;
        grid-column-end: -1;

        .directory-table__label {
          margin-right: 0.25em;
        }
      }
    }

    .--empty {
      display: none;
    }
  }
}
