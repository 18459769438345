.user-info {
  &.medium {
    display: flex;

    .user-image {
      width: 55px;
      margin-right: 0;
    }
  }
}
