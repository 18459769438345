.form-kit__control-password {
  width: 100%;
  height: 100%;
  padding: 0 0 0 0.5em !important;
  margin: 0 !important;
  border: 0 !important;
  min-width: 0;

  @include breakpoint(mobile-large) {
    width: 100% !important;
  }

  &:hover,
  &:focus {
    border: 0 !important;
    outline: 0 !important;
  }
}

.form-kit__control-password-wrapper {
  display: flex;
  max-width: 100%;
  width: 100%;
  height: 2em;
  background: var(--secondary);
  border: 1px solid var(--primary-low-mid);
  border-radius: var(--d-input-border-radius);
  box-sizing: border-box;

  @include breakpoint(mobile-large) {
    width: 100% !important;
    height: 2.25em;
  }

  .form-kit__control-password-toggle {
    height: 100%;
  }

  &.is-focused {
    border-color: var(--tertiary);
    outline: 2px solid var(--tertiary);
    outline-offset: -2px;
  }

  &:hover:not(:disabled) {
    .discourse-no-touch & {
      border-color: var(--tertiary);
    }
  }

  &.has-errors {
    border-color: var(--danger);
  }
}
