.download-calendar-modal .remember {
  margin-top: 2em;
}

.download-calendar {
  color: var(--primary-med-or-secondary-med);
}

.user-preferences {
  #user-default-calendar {
    min-width: 175px;
  }
}
