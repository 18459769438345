.topic-notifications-button {
  display: contents;
}

// This is a weird fix for a weird issue in iOS/iPadOS, the browser freezes
// when changing the notification level while in the fullscreen topic footer.
// This prevents the selected option icon from being hidden while saving,
// and somehow that makes Safari happy.
.timeline-fullscreen {
  .topic-notifications-button.is-loading {
    .selected-name .d-icon {
      display: inline-flex;
    }
  }
}
