.user-chooser {
  .select-kit-row.user-row {
    .avatar {
      margin-left: 0;
      margin-right: 0.5em;
    }

    .username {
      color: var(--primary);
      white-space: nowrap;
    }

    .name {
      color: var(--primary-high);
      font-size: var(--font-down-1);
      margin-left: 0.5em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
