.bulk-select-bookmarks-dropdown {
  .select-kit.single-select.dropdown-select-box .select-kit-row {
    .texts .name {
      font-weight: normal;
    }

    .icons {
      font-size: var(--font-down-2);
      margin-right: 0.75em;
      position: relative;
      top: 0.15em;
    }
  }
}
