.form-kit__errors-summary {
  padding: 1em;
  border: 1px solid var(--danger);
  background-color: var(--danger-low);
  width: 100%;
  border-radius: var(--d-border-radius);
  box-sizing: border-box;

  .d-icon-triangle-exclamation {
    color: var(--danger);
  }

  ul {
    margin-block: 0;
  }
}
