html:not(.anon) #topic-footer-buttons {
  flex-direction: column;
  gap: var(--below-topic-margin);

  .pinned-button,
  .topic-notifications-button {
    .reason {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      margin: 0;
    }
  }
}
