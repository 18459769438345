.full-width {
  margin-left: 0;
}

.staff {
  .topic-post:first-child {
    nav.post-controls .post-admin-menu {
      bottom: -125px;
    }
  }
}

.topic-body {
  padding: 0;

  &:first-of-type {
    border-top: none;
  }

  .reply-to-tab {
    z-index: z("base") + 1;
    color: var(--primary-med-or-secondary-med);
  }

  .actions .fade-out {
    .discourse-no-touch & {
      opacity: 0.7;
      transition: background 0.25s, opacity 0.7s ease-in-out;
      animation: none; // replaces jsuites animation on .fade-out
    }

    .discourse-touch & {
      opacity: 1;
    }
  }

  &:hover .actions .fade-out,
  .selected .actions .fade-out {
    opacity: 1;
  }
}

section.post-menu-area {
  position: relative;
  padding-left: var(--topic-body-width-padding);
}

.post-links-container {
  margin-left: var(--topic-body-width-padding);
}

nav.post-controls {
  // for consistency, try to control spacing by editing these variables
  --control-margin: 0.33em;
  --control-icon-space: 0.33em;

  .actions {
    button {
      margin-left: var(--control-margin);

      &.btn-icon-text,
      &.create {
        margin-left: calc(var(--control-margin) * 1.52);

        .d-icon {
          margin-right: var(--control-icon-space);
        }
      }
    }

    // Some buttons can be doubled up, like likes or flags
    .double-button {
      margin-left: var(--control-margin);
    }
  }

  .show-replies {
    display: flex;
    align-items: center;
    margin-left: 0;
    border-radius: var(--d-button-border-radius);

    .topic-post & {
      margin-right: 0.5em;
    }
    white-space: nowrap;

    .d-icon {
      margin-inline: var(--control-icon-space);
      margin-left: 0;
    }

    &[aria-expanded="true"] {
      background: var(--primary-low);
      color: var(--primary-high);
      box-shadow: 0 0 0 1px var(--primary-300);
      z-index: 1;

      .d-icon {
        color: var(--primary-high);
      }

      &:hover,
      &:focus {
        background: var(--primary-300);
        color: var(--primary);
      }
    }
  }
}

pre.codeblock-buttons {
  .copy-cmd:not(.action-complete),
  .fullscreen-cmd:not(.action-complete) {
    opacity: 0;
    transition: 0.2s;
    visibility: hidden;
  }
}

pre.codeblock-buttons:hover {
  .copy-cmd,
  .fullscreen-cmd {
    opacity: 0.7;
    visibility: visible;

    &:hover {
      opacity: 1;
    }
  }
}

.embedded-posts {
  h1,
  h2,
  h3 {
    margin: 10px 0;
  }
  border: 1px solid var(--primary-low);

  .topic-body {
    box-sizing: border-box;
    width: calc(100% - 70px); // [100% - .topic-avatar width]
    // WARNING: overflow hide is required for quoted / embedded images
    // which expect "normal" post width, but expansions are narrower
    overflow: hidden;
  }

  // this is covered by .topic-body .regular on a normal post
  // but no such class structure exists for an embedded, expanded post
  .cooked {
    margin-top: 15px;
  }

  .topic-avatar {
    padding-left: 25px;
    padding-top: 15px;
  }

  .collapse-down,
  .collapse-up {
    position: absolute;
    color: var(--primary-medium);
    background: var(--secondary);
    border: 1px solid var(--primary-low);
    padding: 6px 9px 8px;
    z-index: 99; // Needs to be higher than topic-avatar

    .d-icon {
      color: currentcolor;
    }

    .discourse-no-touch & {
      &:hover {
        background: var(--primary-low);
        color: var(--primary-high);

        .d-icon {
          color: currentcolor;
        }
      }
    }
  }

  // bottom means "reply expansion" below a post
  &.bottom {
    position: relative;
    max-width: calc(100% - 66px);
    margin-bottom: 30px;
    border: none;

    > div {
      position: relative;

      &:last-of-type {
        margin-bottom: 0;

        .row {
          // Main reply line
          &::before {
            content: "";
            position: absolute;
            top: -22px;
            width: 1px;
            height: calc(100% + 1.25em);
            background: var(--primary-300);
            left: 32px;
          }
        }
      }

      .row {
        padding-bottom: 0.5em;

        // Main reply line
        &::before {
          content: "";
          position: absolute;
          top: -22px;
          width: 1px;
          height: calc(100% + 1.5em);
          background: var(--primary-300);
          left: 32px;
        }

        .topic-avatar {
          border-top: none;
          padding-left: 9px;
          position: relative;
        }

        .topic-body {
          border-top: none;
          padding-bottom: 2.5em;

          .topic-meta-data {
            position: unset;

            .post-link-arrow {
              position: absolute;
              bottom: 0.75em;

              .archetype-private_message & {
                bottom: 0;
              }

              .post-info.arrow {
                display: block;
                margin-right: 0;

                .d-icon {
                  margin-left: 0;
                }

                &:hover,
                &:focus {
                  color: var(--primary-high);
                }
              }
            }
          }

          .cooked {
            margin-top: 0.25em;
            padding-top: 0.5em;
          }
        }
      }

      &.hidden {
        display: block;
        opacity: 0;
      }
    }

    .collapse-up {
      transform: translate(-50%, -164%);
      background: var(--primary-low);
      color: var(--primary-high);
      border: 1px solid var(--primary-300);
      padding: 6px;
      left: 32px;
      bottom: -3em;
      z-index: 1;

      .archetype-private_message & {
        display: flex;
      }

      .d-icon {
        transform: scale(0.871);
      }

      .discourse-no-touch & {
        &:hover,
        &:focus {
          color: var(--primary);
          background: var(--primary-300);
        }
      }
    }

    .load-more-replies {
      font-size: var(--font-down-1);
      position: absolute;
      left: 55%;
      transform: translate(-50%, 50%);
      padding: 0.35em 0.5em;
    }

    .topic-avatar {
      padding-left: 1em;
    }
  }

  // top means "in reply to expansion" above a post
  &.top {
    margin-left: 0;
    border: none;

    .collapse-down {
      transform: translate(17%, 230%);
      z-index: 1;
    }
    width: calc(
      var(--topic-body-width) + (var(--topic-body-width-padding) * 2) +
        var(--topic-avatar-width) - (var(--topic-avatar-width) + 2px)
    ); // 2px accounts for left and right borders

    .row {
      .topic-body,
      .topic-avatar {
        border-top: none;
      }

      .topic-avatar {
        padding-left: 0;
      }

      .topic-body {
        overflow: visible;

        &::before {
          content: "";
          position: absolute;
          top: 16px;
          width: 1px;
          height: calc(100% + 1.5em);
          background: var(--primary-300);
          left: -22px;
        }
      }
    }
  }

  &.top.topic-body {
    padding: 0;
  }

  .post-date {
    color: var(--primary-med-or-secondary-high);
  }

  .d-icon-arrow-up,
  .d-icon-arrow-down {
    margin-left: 5px;
  }

  .reply:first-of-type .row {
    border-top: none;
  }

  .topic-meta-data {
    position: relative;
  }

  .topic-meta-data h5 {
    position: absolute;
    z-index: z("base");
    font-size: var(--font-down-1);

    a {
      font-weight: bold;
      color: var(--primary-low-mid-or-secondary-high);
    }
  }

  .arrow {
    color: var(--primary-med-or-secondary-high);
  }
}

.post-action {
  .relative-date {
    margin-left: 5px;
  }

  .avatar {
    margin-right: 2px;
  }
}

span.post-count {
  background: var(--primary);
  color: var(--secondary);
  opacity: 0.8;
}

button.expand-post {
  margin-top: 10px;
  margin-left: var(--topic-body-width-padding);
}

video {
  max-height: 500px;
}

.video {
  // Height determined by aspect-ratio
  max-height: 500px;

  > video {
    max-height: unset;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.btn-group {
  position: relative;
}

.dropdown-toggle {
  float: left;
  position: relative;
}

.post-select {
  float: right;
  margin-right: 20px;
  margin-top: -20px;
}

.deleted-user-avatar {
  font-size: var(--font-up-6);
}

.info-line {
  margin: 10px 0;
  color: var(--primary);
}

/* solo quotes */
blockquote {
  /* leave browser defaults for top and bottom here */
  margin-left: 0;
  margin-right: 0;
  padding: 12px;
}

/* quotes with attribution */
.quote {
  & > blockquote {
    .onebox-result {
      background-color: var(--primary-very-low);
    }
  }

  aside {
    .quote,
    .title,
    blockquote,
    .onebox,
    .onebox-result {
      background: var(--primary-very-low);
      border-left: 5px solid var(--primary-low);
    }

    aside.quote > blockquote,
    aside.quote > .title {
      border-left: 0;
    }
  }
}

.topic-post-visited {
  + .topic-post {
    .topic-avatar,
    .topic-body {
      border-top: none;
    }
  }
}

// variables are used to calculate the width of .gap
.topic-body {
  width: calc(var(--topic-body-width) + (var(--topic-body-width-padding) * 2));
  float: left;
  min-width: 0; // prevents some elements, like <pre>, from blowing out the width
  position: relative;
  border-top: 1px solid var(--primary-low);
  padding: 0.8em 0 0 0;

  .topic-meta-data {
    padding: 0 var(--topic-body-width-padding) 0.25em
      var(--topic-body-width-padding);
  }

  .cooked {
    padding: 1em var(--topic-body-width-padding) 0.25em
      var(--topic-body-width-padding);
  }

  .group-request {
    padding: 0.5em var(--topic-body-width-padding) 0
      var(--topic-body-width-padding);
  }

  a.expand-hidden {
    padding-left: var(--topic-body-width-padding);
  }
}

.topic-avatar {
  border-top: 1px solid var(--primary-low);
  padding-top: 15px;
  width: $topic-avatar-width;
  float: left;
  z-index: z("base") + 1;
  height: 100%;
  overflow-anchor: none;
}

.gap {
  box-sizing: border-box;
}

.topic-area > .loading-container {
  // loader needs to be same width as posts
  width: calc(
    var(--topic-body-width) + var(--topic-avatar-width) +
      (var(--topic-body-width-padding) * 2)
  );
  max-width: 100%;
}

/* hide the reply border above the time gap notices */
.time-gap + .topic-post .topic-body,
.time-gap + .topic-post .topic-avatar {
  border-top: none;
}

.time-gap + .topic-post .embedded-posts.top {
  border-bottom: none;
}

.posts-wrapper {
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;
}

.dropdown,
.multiselect {
  position: relative;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px solid var(--primary);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  margin-left: 5px;
}

.selected-posts {
  width: 200px;
  position: fixed;
  z-index: z("dropdown") + 1; // 1 higher than .select-posts
  box-shadow: var(--shadow-card);
  padding: 0.714em;
  margin-bottom: 5px;
  right: 10px;
  @include breakpoint(extra-large, min-width) {
    right: auto;
    margin-left: 330px;
    left: 50%;
  }

  button {
    width: 100%;
    margin: 4px auto;
    display: inline-block;
    text-align: left;
  }

  &.hidden {
    display: none;
  }

  .controls {
    margin-top: 10px;
  }

  p {
    font-size: var(--font-down-1);
    margin: 0 0 10px 0;
  }

  p.cancel {
    margin: 10px 0 0 0;
  }

  h3 {
    font-size: var(--font-up-4);
    color: var(--primary);
    margin-bottom: 5px;

    .d-icon {
      margin-right: 7px;
    }
  }
}

a.attachment::before {
  display: inline-block;
  margin-right: 4px;

  // ideally, the SVG used here should be in HTML and reference the SVG sprite
  content: svg-uri(
    '<svg xmlns="http://www.w3.org/2000/svg" width="14px" height="16px" viewBox="0 0 512 512" fill="#{$tertiary}"><path d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg>'
  );
  vertical-align: middle;
}

.topic-meta-data {
  align-items: center;

  .names {
    display: flex;
    align-items: center;
  }

  &::after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  .post-info {
    a {
      color: var(--primary-med-or-secondary-med);
    }

    a.wiki {
      color: var(--wiki);
    }
  }
}

.who-liked,
.who-read {
  margin-top: 20px;
  margin-bottom: 0;
  width: 100%;
  text-align: right;
}

span.highlighted {
  background-color: var(--tertiary-low);
}

.first.new-user:not(.staff) a {
  color: var(--primary-low-mid);
}

.topic-post.sticky-avatar {
  > article > .row > .topic-avatar {
    position: sticky;
    top: calc(var(--header-offset) - 0.25em);
    margin-bottom: 25px;
  }
}

/* Tablet (portrait) ----------- */

@media all and (max-width: 790px) {
  .topic-avatar {
    width: 45px;
  }

  .topic-post .reply-to-tab {
    right: 15%;
  }

  .topic-body {
    box-sizing: border-box;
    width: calc(100% - 47px); //100% - [width of .topic-avatar + 2px]
  }

  .embedded-posts {
    // top means "in reply to expansion" above a post
    &.top {
      width: calc(100% - 56px); // [100% - margin-left]
    }
  }
}
