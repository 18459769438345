.post-action-feedback-alert {
  position: absolute;
  top: -1.5rem;
  left: 60%;
  transform: translateX(-50%);
  color: var(--success);
  padding: 0.25rem 0.5rem;
  white-space: nowrap;
  font-size: var(--font-down-2);
  opacity: 0;
  z-index: calc(z("timeline") + 1);
}

.--transition {
  .post-action-feedback-alert,
  .post-action-feedback-svg {
    transition: opacity 0.25s, color 0.25s;
  }
}

.--activated {
  .post-action-feedback-alert,
  .post-action-feedback-svg {
    opacity: 1;
  }

  .post-action-feedback-alert {
    &.-success {
      color: var(--success);
    }

    &.-fail {
      color: var(--danger);
    }
  }
}
