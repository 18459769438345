.user-ignore {
  .btn-icon-text {
    margin-top: 0.5em;
  }
}

.ignored-list {
  overflow: auto;
  max-height: 150px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--secondary);
  min-height: 30px;
  box-sizing: border-box;
}

.ignored-user-list-item {
  border: 1px solid var(--primary-medium);
  padding: 0;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.ignored-user-name {
  padding-left: 10px;
  padding-right: 10px;
}
