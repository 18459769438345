.empty-state {
  color: var(--primary);
  margin: 0;
  padding: 0.5em 1em;
  display: flex;
  flex-direction: column;

  .empty-state-title {
    font-weight: 700;
    padding: 0;
    margin: 0;
    font-size: var(--font-up-1);
  }

  .empty-state-body {
    padding: 0;
    margin: 0;
  }
}
