// Desktop
// global styles that apply to the Discourse application specifically
// BEWARE: changing these styles implies they take effect anywhere they are seen
// throughout the Discourse application
:root {
  --d-max-width: 1110px;
}

// Base Elements
body.widget-dragging {
  cursor: ns-resize;
}

body.dragging {
  cursor: ns-resize;
}

// Common classes
.boxed {
  height: 100%;
}

.form-vertical {
  .control-group {
    margin-bottom: 1.25em;
  }

  .controls:not(.controls-dropdown) + .controls {
    margin-top: 0.5em;
  }
}

code,
pre {
  font-family: var(--d-font-family--monospace);
}

// this removes the unwanted top margin on a paragraph under a heading
h1,
h2,
h3,
h4,
h5,
h6 {
  + p {
    margin-top: 0;
  }
}

form {
  margin: 0;
}

label,
input,
button,
select,
textarea {
  font-size: var(--font-0);
  font-weight: normal;
  line-height: var(--line-height-medium);
}

select,
textarea {
  display: inline-block;
  margin-bottom: 9px;
  font-size: var(--font-0);
  line-height: var(--line-height-large);
  color: var(--primary);
}

input,
textarea {
  width: 210px;
  margin-left: 0;
}

select,
input[type="file"] {
  line-height: var(--line-height-large);
}

select {
  width: 220px;
  background: var(--secondary);

  &[multiple],
  &[size] {
    height: auto;
  }
}

.input {
  &-xxlarge,
  &-xxlarge + .control-instructions {
    width: 530px;
  }
}

.controls-dropdown {
  margin-bottom: 10px;
}

.control-instructions {
  color: var(--primary-medium);
  font-size: 0.875rem;
}

.control-group {
  margin-bottom: 9px;
}

.form-horizontal {
  input[type="text"],
  input[type="password"],
  textarea {
    margin-bottom: 0;
  }

  .control-group {
    @include clearfix;
    margin-bottom: 18px;
  }

  .control-indent {
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .control-label {
    margin: 6.5px;
    float: left;
    width: 140px;
    text-align: right;
    font-weight: bold;
  }

  .controls {
    margin-left: 160px;
  }
}

// Media Queries
@include breakpoint(mobile-extra-large) {
  body {
    min-width: 0;
  }

  .wrap,
  .full-width {
    min-width: 0;
  }
}

body.has-sidebar-page {
  .wrap {
    // increase page max-width to accommodate sidebar width
    max-width: calc(var(--d-sidebar-width) + var(--d-max-width));
  }

  #main-outlet-wrapper {
    grid-template-columns: var(--d-sidebar-width) minmax(0, 1fr);
    gap: 0 2em;
    @include breakpoint(large) {
      gap: 0 1em;
    }
    padding-left: 0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  body.sidebar-animate {
    #main-outlet-wrapper {
      transition-property: grid-template-columns, max-width;
      transition-timing-function: var(--d-sidebar-animation-ease);
      transition-duration: var(--d-sidebar-animation-time);
    }

    .d-header-wrap .wrap {
      transition: max-width var(--d-sidebar-animation-time)
        var(--d-sidebar-animation-ease);
    }
  }
}
