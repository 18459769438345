@mixin bordered-horizontal-nav($border-width) {
  li {
    flex: 1 1 auto;
    margin: 0;
    display: flex;

    a {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      @include breakpoint(extra-large) {
        font-size: var(--font-0);
      }

      span {
        line-height: normal;
        @include ellipsis;
      }
    }
  }
}

@mixin bordered-nav-pills {
  width: 100%;
  margin: 0;

  .d-icon {
    font-size: var(--font-down-1);
  }

  &.main-nav {
    @include breakpoint(medium) {
      li {
        .d-icon {
          font-size: var(--font-up-1);
          margin: 0;
        }

        span {
          display: none;
        }
      }
    }

    .has-sidebar-page & {
      @include breakpoint(large) {
        span {
          display: none;
        }
      }
    }
  }
}

.new-user-wrapper {
  .user-navigation {
    --user-navigation__border-width: 4px;
    border-bottom: 1px solid var(--primary-low);

    .nav-pills {
      @include bordered-nav-pills;
      @include bordered-horizontal-nav(var(--user-navigation__border-width));
    }
  }

  .user-navigation-primary {
    [class*="horizontal-overflow-nav__scroll"] {
      font-size: var(--font-up-1);

      .d-icon {
        margin-top: 0.15em; // minor alignment
      }
    }
  }

  .user-navigation-secondary {
    position: relative;
    display: flex;
    min-width: 0;
    gap: 0 0.5em;
    font-size: var(--font-down-1);

    .select-kit .select-kit-header {
      height: 100%;
      padding: 0.5em 1em;
    }

    .select-kit-collection {
      font-size: var(--font-up-1);
    }

    .user-nav-messages-dropdown {
      // manage long group names
      max-width: 20vw;
      min-width: 7em;

      .select-kit-selected-name,
      .name {
        @include ellipsis;
      }

      .name {
        min-width: 0;
      }
    }

    .category-breadcrumb {
      width: auto;
      padding-top: var(--navigation-secondary-padding-top);
      @include breakpoint(extra-large) {
        font-size: var(--font-down-1);
      }

      > li {
        margin: 0;
      }
    }

    .navigation-controls {
      padding-top: var(--navigation-secondary-padding-top);
      flex-wrap: nowrap;
      @include breakpoint(extra-large) {
        font-size: var(--font-down-1);
      }

      span {
        display: inline-flex;
      }
    }

    .nav-pills {
      flex: 1 1 auto;
      justify-content: flex-start;
      height: 100%;

      li {
        flex: 1 0 auto;

        a {
          span {
            text-overflow: unset;
          }
        }
      }
    }
  }

  .empty-state {
    padding: 0;
    margin: 1em 0;
  }

  .solo-preference {
    grid-column-start: 1;
    grid-column-end: -1;
  }
}

.user-preferences-tracking-page {
  .user-preferences .form-vertical {
    width: 100%;
  }
}

.user-preferences__tracking-topics-wrapper {
  margin-bottom: 3em;

  .control-label {
    margin-bottom: 1em;
  }
}

.user-preferences__tracking-categories-tags-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16em, 1fr));
  gap: 2em;

  .control-group {
    width: 100%;
    min-width: 16em;
  }
}
