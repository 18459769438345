// shared styles for user and group cards
.user-card,
.group-card {
  // avatar - names - controls
  .first-row {
    .names {
      flex: 1 1 auto;
    }

    .usercard-controls {
      max-width: 225px;

      button {
        min-width: 150px;
      }
    }

    .user-profile-link {
      &:focus-visible {
        border: 1px solid;
        @include default-focus;
      }
    }
  }

  .names__primary {
    .d-icon {
      font-size: var(--font-down-1);
    }
  }

  @include breakpoint("mobile-extra-large") {
    // important for keeping the usercard uncropped
    // at 200% - 400% zoom levels
    --avatar-width: 4em;
    --avatar-margin: 0;

    .names__primary {
      font-size: var(--font-up-2);
    }

    .first-row {
      gap: 0.5em 0;
      flex-wrap: wrap;

      .usercard-controls {
        max-width: unset;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 0 0.5em;
      }

      li {
        flex: 1 1 0;

        &:empty {
          display: none;
        }
      }
    }
  }
}

// styles for user cards only
.user-card {
  // badges
  .badge-section {
    .user-badge {
      display: block;
      max-width: 185px;
    }

    .more-user-badges {
      max-width: 125px;
    }
  }
}
