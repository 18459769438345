// --------------------------------------------------
// Discourse header
// --------------------------------------------------
.d-header {
  height: 4em;

  #site-text-logo {
    font-size: var(--font-up-3);
  }

  .extra-info {
    &:not(.two-rows) {
      .header-title {
        margin-bottom: 0;
      }
    }
  }
}

@include breakpoint(mobile-extra-large) {
  .extra-info-wrapper {
    display: none;
  }
}

#main {
  position: relative;
}

.search-link .blurb {
  color: var(--secondary-medium);
  display: block;
  word-wrap: break-word;
  font-size: var(--font-down-2);
  line-height: var(--line-height-large);

  .search-highlight {
    color: var(--primary-high-or-secondary-low);
  }
}
