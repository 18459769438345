.uploaded-image-preview {
  background: var(--primary-medium) center;
  background-size: cover;
  position: relative;
  max-width: 400px;
  width: 100%;
  height: 150px;
  margin-bottom: 0.5em;
  box-sizing: border-box;

  &.uppy-is-drag-over {
    .d-icon-far-image {
      color: var(--success);
    }
  }

  .meta {
    display: none;
  }

  .placeholder-overlay {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
  }

  .image-upload-controls {
    position: relative;
    display: flex;
    padding: 10px;
    gap: 0.5em;

    .image-uploader-lightbox-btn {
      margin-left: auto;
    }
  }
}

.image-uploader.no-repeat {
  .uploaded-image-preview {
    background-repeat: no-repeat;
  }
}

.image-uploader.contain-image {
  .uploaded-image-preview {
    background-size: contain;
  }
}

.hidden-upload-field {
  visibility: hidden;
  position: absolute;
}
