.d-icon.d-icon-d-regular,
.d-icon.d-icon-d-muted,
.d-icon.d-icon-d-watching-first,
.d-icon.d-icon-d-watching-first-post {
  color: var(--primary-high);
}

.d-icon.d-icon-d-tracking,
.d-icon.d-icon-d-watching {
  color: var(--tertiary);
}
