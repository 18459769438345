.reviewable {
  background: var(--d-content-background);

  .flagged-post-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .status {
    color: var(--primary-medium);

    span.approved {
      color: var(--success);

      .d-icon {
        color: currentcolor;
      }
    }

    span.rejected {
      color: var(--danger);

      .d-icon {
        color: currentcolor;
      }
    }
  }

  .explain {
    margin-left: 0.5em;
  }

  .nav-pills {
    margin: 0 0 1em;
  }

  .reviewable-container {
    display: flex;
    flex-direction: row;
    margin-top: 1em;

    .reviewable-list {
      flex: 1;
      box-sizing: border-box;
      max-width: 760px; // Match topic post width
      min-width: 0;
      margin-right: auto;
    }

    .reviewable-filters {
      width: 250px;
      height: 100%;
      box-sizing: border-box;
    }

    .reviewable-list + .reviewable-filters {
      margin-left: 1em;
    }
  }
}

.reviewable-settings {
  h4 {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .saved {
    margin-left: 0.5em;
  }

  .reviewable-score-type {
    display: flex;
    margin-bottom: 0.5em;

    .select-kit {
      min-width: 10em;
    }

    .title {
      width: 30%;
    }
  }
}

.reviewable-user-info {
  max-width: 100%;
  margin: 0.5em 0;

  .reviewable-user-fields {
    margin-bottom: 2em;
  }

  .reviewable-user-details {
    border-bottom: 1px solid var(--primary-low);
    padding-bottom: 0.25em;
    display: flex;

    .name {
      width: 8em;
      font-weight: bold;
      margin-right: 1em;
    }

    .value {
      max-width: calc(
        100% - 8em - 1em
      ); // subtracting width of name and margin-right
      word-wrap: break-word;
    }
    margin-bottom: 0.5em;
  }
}

.no-review {
  margin-top: 1em;
}

.reviewable-filters {
  background-color: var(--primary-very-low);
  padding: 1em;
  margin-bottom: 1em;

  .reviewable-filter {
    display: flex;
    flex-direction: column;
    margin: 0 0 1em 0;

    .filter-label {
      margin: 0 0 0.5em 0;
    }

    .score-filter {
      margin: 0;
      width: 100%;
    }

    .category-chooser {
      width: 100%;
    }
  }
}

.reviewable-claimed-topic {
  display: flex;
  align-items: center;

  .btn-small {
    margin-left: 0.5em;
  }
}

.claimed-actions {
  display: flex;
  flex: 1 1 100%;
  margin-right: 0;
  align-items: center;
  margin-bottom: 0.5em;
}

.claimed-by {
  display: flex;
  align-items: center;

  .claimed-username {
    margin-left: 0.5em;
  }
}

.reviewable-topics {
  width: 100%;

  tbody {
    td {
      padding: 0.5em;
    }
  }

  .reviewable-details {
    display: flex;
    justify-content: flex-end;

    .btn {
      display: flex;
      align-items: center;
      margin-left: 1em;
    }
  }
}

.reviewable-filters {
  .topic-filter .btn {
    display: flex;
    width: auto;
  }

  .score-filter {
    width: 5em;
  }
}

.user-flag-percentage {
  display: flex;
  align-items: center;
  margin-left: 0.5em;

  .percentage-label {
    margin-right: 0.25em;

    &.agreed {
      color: var(--success);

      + .d-icon {
        color: var(--success);
      }
    }

    &.disagreed {
      color: var(--danger);

      + .d-icon {
        color: var(--danger);
      }
    }

    &.ignored {
      color: var(--primary-medium);
    }
  }

  .d-icon {
    font-size: 0.9em;
  }
}

.reviewable-item {
  background: var(--primary-very-low);
  padding: 1.5rem;

  .topic-statuses {
    font-size: var(--font-up-2);
  }

  .reviewable-meta-data {
    color: var(--primary-med-or-secondary-med);
    display: flex;
    width: 100%;
    margin-bottom: 0.5em;
    font-size: var(--font-down-1);
    align-items: baseline;

    .reviewable-type {
      margin-right: 0.25em;
      padding: 0.25em 0.5em;
      text-transform: uppercase;
      font-size: var(--font-down-2);
      color: var(--secondary);
      border-radius: 8px;
      background-color: var(--secondary-high);

      &.-flagged-post,
      &.-flagged-chat-message {
        background-color: var(--danger);
      }

      &.-queued-post,
      &.-queued-topic,
      &.-user {
        background-color: var(--tertiary);
      }
    }

    .reply-count {
      margin-left: 1em;
    }

    .created-at {
      margin-left: 1em;
      margin-right: auto;

      a {
        color: var(--primary-med-or-secondary-med);
      }
    }

    .score {
      font-size: var(--font-down-1);
    }
  }

  .reviewable-contents {
    display: flex;
    flex-wrap: wrap;
    margin: 1.5rem 0 1rem;
    background: var(--secondary);
    padding: 1rem;
  }

  .reviewable-actions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 100%;

    button {
      white-space: nowrap;
    }

    .approve-post,
    .approve-post > summary {
      background-color: var(--success);
      color: var(--secondary);
    }

    .reject-post,
    .reject-post > summary {
      background-color: var(--danger);
      color: var(--secondary);
    }

    .reviewable-action,
    .reviewable-action-dropdown {
      margin-right: 0.5em;
    }
  }
  padding-bottom: 1em;
}

.reviewable-stale {
  opacity: 0.7;
}

.reviewable-deleted {
  .reviewable-contents .post-contents .post-body {
    background-color: var(--danger-low-mid);
    padding: 0.5em;
  }
}

.blur-images {
  img:not(.avatar, .emoji) {
    filter: blur(10px);
    transition: 0.2s ease-in-out;

    &:hover {
      filter: blur(0);
      transition: 0.2s ease-in-out;
    }
  }
}

.reviewable-histories {
  margin-top: 1em;
}

.reviewable-scores {
  margin-top: 1rem;
  min-width: 50%;
  color: var(--primary-high);

  &__table-wrapper {
    overflow-x: scroll;
  }

  th {
    white-space: nowrap;
  }

  .reviewed-by {
    .date {
      margin-left: 0.5em;
    }
  }

  .user,
  .reviewed-by {
    display: flex;
    align-items: center;
  }

  .user-flag-percentage {
    margin-left: 0.5em;
  }

  .d-icon {
    font-size: var(--font-down-1);
    color: var(--primary-medium);
  }

  .badge-notification {
    line-height: var(--line-height-medium);
    vertical-align: text-top;
  }

  .approved,
  .approved svg {
    color: var(--success);
  }

  .rejected,
  .rejected svg {
    color: var(--danger);
  }

  tbody {
    border-width: 1px;

    td {
      white-space: nowrap;
      vertical-align: baseline;

      &.user a,
      &.reviewed-by a {
        max-width: 150px;
        @include ellipsis;
      }
    }

    > tr > th {
      text-align: left;
    }

    > tr > th,
    > tr > td {
      &:not(:empty) {
        padding: 0.5em;
      }
      @include breakpoint("mobile-large") {
        @include ellipsis;
        padding-right: 0.5em;
      }
    }
  }
}

.reviewable-score-reason {
  margin: 0.5em 0;
  max-width: var(--topic-body-width);
  margin-bottom: 0.5em;
}

.reviewable-conversation {
  margin: 0.5em 0;

  .reviewable-conversation-post {
    max-width: var(--topic-body-width);
    margin-bottom: 0.5em;

    .username {
      font-weight: bold;
      margin-right: 0.25em;
    }
  }

  .controls {
    margin-top: 0.25em;
  }
}

.reviewable-item {
  margin-block: 3rem;

  .show-raw-email {
    color: var(--primary-medium);
    font-size: var(--font-down-2);
  }

  .post-title {
    background-color: yellow;
  }

  .created-by {
    margin-right: 1em;
    padding-top: 0.35em;
    @include breakpoint("mobile-large") {
      float: left;
      margin-bottom: 1em;
    }
  }

  .names {
    font-weight: bold;
  }

  .post-contents-wrapper {
    width: 100%;
    margin-top: 1em;
    min-width: 275px;
    word-break: break-word;
    @include breakpoint("mobile-large", min-width) {
      display: flex;
    }
  }

  .reviewable-post-header {
    display: flex;
    justify-content: space-between;
    width: var(--topic-body-width);
    align-items: center;
    max-width: 100%;

    .reviewable-reply-to {
      display: flex;
      align-items: center;
      color: var(--primary-medium);
      font-size: 0.9em;

      .d-icon {
        margin-right: 0.5em;
      }
    }
  }

  .post-contents {
    width: 100%;
    min-width: 0; // Flexbox fix
  }

  .post-body {
    position: relative;
    max-width: var(--topic-body-width);
    margin-top: 0.5em;

    &.is-collapsed {
      max-height: 50vh;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 25%;
        width: 100%;
        background: linear-gradient(to bottom, transparent, var(--secondary));
      }

      + .post-body__toggle-btn {
        transform: translateX(-50%) translateY(-50%);
      }
    }

    p {
      margin-bottom: 0;
    }

    &__toggle-btn {
      background: var(--primary-very-low);
      color: var(--primary-high);
      font-size: var(--font-down-2);
      box-shadow: var(--shadow-dropdown);
      margin-left: 50%;
      transform: translateX(-50%);
      border-radius: 4px;

      .d-icon {
        color: var(--primary-high);
      }

      &:hover {
        background: var(--primary-very-low);
        color: var(--tertiary);

        .d-icon {
          color: var(--tertiary);
        }
      }
    }

    aside {
      margin: 0 0 1em 0;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    pre,
    code {
      word-break: break-all;
    }
  }

  .post-topic {
    width: 100%;
    color: var(--primary-medium);
    margin-bottom: 0.75em;

    .title-text {
      font-weight: bold;
      color: var(--primary);
      display: block;
      font-size: var(--font-up-2);
      margin-right: 0.75em;
    }

    .badge-category__wrapper {
      margin-left: 0.25em;
    }

    .discourse-tag {
      vertical-align: bottom;
      padding-left: 0.1em;
    }
  }

  &__context-question {
    margin-block: 1rem;
  }
}

.editable-fields {
  .editable-created-by {
    display: flex;

    .avatar {
      margin-right: 0.25em;
    }
    margin-bottom: 0.5em;
  }
  width: 100%;

  .editable-field {
    .mini-tag-chooser {
      margin: 0;
    }

    .reviewable-input-text {
      width: 100%;
      margin-bottom: 0;
    }

    .reviewable-input-textarea {
      width: 100%;
      height: 10em;
    }
    margin-bottom: 0.5em;
  }
}
