#discourse-tooltip {
  --d-tooltip-background: var(--secondary);
  --d-tooltip-border: var(--primary-medium);
  background-color: var(--d-tooltip-background);
  position: absolute;
  z-index: z("tooltip");
  border: 1px solid var(--d-tooltip-border);
  max-width: 400px;
  margin-top: 25px;
  overflow-wrap: break-word;
  display: none;
  font-size: var(--font-0);
  font-weight: 500;

  &.retina {
    border: 0.5px solid var(--d-tooltip-border);
  }

  .tooltip-pointer {
    position: relative;
    background: var(--d-tooltip-background);
  }

  .tooltip-pointer::before,
  .tooltip-pointer::after {
    position: absolute;
    pointer-events: none;
    border: solid transparent;
    bottom: 100%;
    content: "";
    height: 0;
    width: 0;
  }

  .tooltip-pointer::after {
    border-bottom-color: var(--d-tooltip-background);
    border-width: 8px;
    left: 50%;
    margin-left: -8px;
    margin-bottom: -0.5px;
  }

  .tooltip-pointer::before {
    border-bottom-color: var(--d-tooltip-border);
    border-width: 9px;
    left: 50%;
    margin-left: -9px;
    margin-bottom: -0.5px;
  }

  .tooltip-content {
    padding: 1em;
    max-width: 250px;
    font-size: var(--font-down-1);
    color: var(--primary);
    box-shadow: var(--shadow-dropdown);
    line-height: 1.4em;
  }
}
