.form-template-field {
  position: relative;

  input,
  textarea,
  select {
    width: 100%;
  }

  &__dropdown {
    border: 1px solid var(--primary-medium);
    border-radius: var(--d-input-border-radius);
    padding: 0.5em 0.65em;
    font-size: var(--font-0);
  }

  &__multi-select {
    border: 1px solid var(--primary-medium);
    border-radius: var(--d-input-border-radius);
    padding: 0.5em 0.65em;
    font-size: var(--font-0);
  }

  // END of what should be removed after updating dropdown/multi-select to use select-kit
  &__required-indicator {
    color: var(--danger);
    margin-left: 0.5em;
    font-size: var(--font-down-4);
  }

  &__uploaded-files {
    list-style: none;
    margin-left: 0;

    li {
      padding: 0.5rem;
      margin-block: 0.25rem;
      border: 1px solid var(--primary-low-mid);
      background: var(--primary-low);
      border-radius: var(--d-border-radius);
      display: flex;
      align-items: center;

      a {
        @include ellipsis;
        width: 70%;
      }

      span {
        color: var(--primary-high);
        margin-left: auto;
        font-size: var(--font-down-1);
      }
    }

    .d-icon {
      color: var(--tertiary);
      margin-right: 0.5rem;
    }
  }

  &__textarea {
    min-height: 100px;
  }

  &__description {
    display: inline-block;
    margin-bottom: 0.25rem;
    font-size: var(--font-down-1);
    color: var(--primary-medium);
  }
}
