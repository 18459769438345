.emoji-value-list {
  .value {
    .shift-up-value-btn,
    .shift-down-value-btn {
      display: none;
    }

    &:hover {
      .shift-up-value-btn,
      .shift-down-value-btn {
        display: block;
      }
    }
  }
}
