.select-kit.email-group-user-chooser {
  &.full-width-wrap {
    width: unset;
  }

  &.is-expanded .select-kit-body {
    width: 300px;
  }

  .select-kit-row.email-group-user-chooser-row {
    .identifier {
      color: var(--primary);
      white-space: nowrap;
      line-height: var(--line-height-medium);
      margin-right: 0.5em;
    }

    .name {
      color: var(--primary-high);
      font-size: var(--font-down-1);
      @include ellipsis;
    }

    .avatar,
    .d-icon {
      margin-left: 0;
      margin-right: 0.5em;
    }
  }

  .select-kit-header {
    .x-more-item {
      background: var(--primary-low);
      padding: 0.25em;
      flex: 1;
      align-items: center;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      margin: 2px 0 0 3px;
      float: left;
      height: 30px;
      color: inherit;
      outline: none;
    }
  }
}
