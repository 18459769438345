@keyframes d-menu-opening {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fk-d-menu {
  width: max-content;
  position: absolute;
  top: 0;
  max-width: 600px;
  display: flex;
  padding: 0;
  z-index: z("dropdown");

  &__trigger {
    .touch & {
      @include unselectable;
    }
  }

  &.-animated {
    animation: d-menu-opening 0.15s ease-in;

    &[data-placement^="bottom"] {
      transform-origin: top center;
    }

    &[data-placement^="top"] {
      transform-origin: bottom center;
    }

    &[data-placement^="right"] {
      transform-origin: center left;
    }

    &[data-placement^="left"] {
      transform-origin: center right;
    }
  }

  &[data-strategy="fixed"] {
    position: fixed;
  }

  &__inner-content {
    display: flex;
    border-radius: var(--d-border-radius);
    background-color: var(--secondary);
    border: 1px solid var(--primary-low);
    box-shadow: var(--shadow-menu-panel);
    overscroll-behavior: contain;
    overflow: auto;
  }

  .arrow {
    position: absolute;
    color: var(--secondary);
  }

  &[data-placement^="top"] {
    .arrow {
      bottom: -9px;
      rotate: 180deg;
    }
  }

  &[data-placement^="bottom"] {
    .arrow {
      top: -10px;
    }
  }

  &[data-placement^="right"] {
    .arrow {
      rotate: -90deg;
      left: -9px;
    }
  }

  &[data-placement^="left"] {
    .arrow {
      rotate: 90deg;
      right: -9px;
    }
  }
}

//should probably be renamed fk-d-menu__list
.dropdown-menu {
  min-width: 200px;
  padding: 0;
  margin: 0;
  list-style: none;

  &__item {
    .btn {
      padding: 0.5em 1rem;
      width: 100%;
      justify-content: flex-start;
      background: rgba(0, 0, 0, 0);

      &.-selected {
        background: var(--d-hover);
      }

      &.btn-danger {
        color: var(--danger);

        .d-icon {
          color: inherit;
        }
      }

      &:hover,
      &:focus {
        background: var(--d-hover);
        color: var(--primary);

        .d-icon {
          color: inherit;
        }

        &.btn-danger {
          background: var(--danger-low);
          color: var(--danger);

          .d-icon {
            color: var(--danger-hover);
          }
        }
      }
    }
  }

  &__divider {
    margin: 0;
  }
}
