.menu-panel.slide-in {
  position: fixed;
  right: 0;
  box-shadow: var(--shadow-header);

  .panel-body {
    width: 100%;
  }
}

.header-cloak {
  display: none;
}

.menu-panel.drop-down {
  position: absolute;

  // positions are relative to the .d-header .panel div
  top: 100%; // directly underneath .panel
  right: -10px; // 10px to the right of .panel - adjust as needed
  max-height: calc(100dvh - var(--header-offset) - 1em);
  border-radius: var(--d-border-radius-large);
}

.menu-panel {
  border: 1px solid var(--primary-low);
  box-shadow: var(--shadow-menu-panel);
  background-color: var(--secondary);
  z-index: z("header");
  padding: 0.5em;
  width: 320px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  hr {
    margin: 3px 0;
  }

  .panel-header {
    position: absolute;
    right: 20px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .panel-body {
    display: flex;
    touch-action: pan-y pinch-zoom;
    overflow: hidden;
    height: 100%;
  }

  .panel-body-contents {
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .panel-body-bottom {
    display: flex;
    flex: 1 0 0%; // safari height fix
    margin-top: 0.5em;
    flex-wrap: wrap;

    .show-all {
      display: flex;
      flex: 1 1 auto;

      button {
        width: 100%;
      }
    }

    .notifications-dismiss {
      margin-left: 0.5em;
    }

    .btn {
      background-color: var(--primary-very-low);
      color: var(--primary-high);

      &:hover,
      &:focus-visible {
        background: var(--primary-low);
        color: var(--primary);
      }
    }
  }

  .badge-notification {
    vertical-align: text-bottom;
  }

  .sidebar-filter {
    width: 100%;
  }
}

.search-menu .menu-panel {
  width: 500px;
}

.user-menu.revamped {
  right: 0;
  width: 320px;
  padding: 0;
  border-top-right-radius: 0;

  .panel-body-bottom {
    flex: 0;
  }

  .menu-tabs-container {
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--primary-low);
    padding: 0.75em 0 0;
    overflow-y: auto;
    overscroll-behavior: contain;
  }

  .tabs-list {
    display: flex;
    flex-direction: column;

    .btn {
      display: flex;
      position: relative;
      border-radius: 0;
      padding: 0.857em;
      @media screen and (max-height: 400px) {
        // helps with 400% zoom level
        font-size: var(--font-down-1);
        padding: 0.5em 0.875em;
      }

      .d-icon {
        color: var(--primary-medium);
      }

      .badge-notification {
        background-color: var(--tertiary-med-or-tertiary);
        position: absolute;
        right: 6px;
        top: 6px;
        font-size: var(--font-down-3);
        @media screen and (max-height: 400px) {
          // helps with 400% zoom level
          right: 0;
          top: 0;
        }
      }

      &.active {
        background-color: var(--d-selected);
      }

      &:hover {
        background-color: var(--d-hover);
      }
    }
  }

  .bottom-tabs {
    border-top: 1px solid var(--primary-low);
  }

  .panel-body-contents {
    display: flex;
    flex-direction: row-reverse;
  }

  .quick-access-panel {
    width: 320px;
    padding: 0.75em;
    padding-bottom: max(env(safe-area-inset-bottom), 0.75em);
    justify-content: space-between;
    box-sizing: border-box;
    min-width: 0; // makes sure menu tabs don't go off screen
  }

  #quick-access-profile {
    display: inline;
    max-height: 99%; //  macOS Chrome sometimes adds an unneeded scrollbar at 100%

    ul {
      flex-wrap: nowrap;
      height: 100%;
      align-items: center;
      overflow-y: auto; // really short viewports
    }

    li {
      flex: 1 1 auto;
      max-height: 3em; // prevent buttons from getting too tall

      > * {
        // button, a, and everything else
        height: 100%;
        align-items: center;
        margin: 0;
        padding: 0 0.5em;
      }

      img.emoji {
        height: 1em;
        width: 1em;
        padding-top: 0.2em;
        margin-right: 0.5em;
      }

      .d-icon {
        padding-top: 0;
      }

      &.enabled .d-icon {
        color: var(--tertiary);
      }
    }

    .set-user-status {
      .emoji {
        padding-top: 0;
      }
    }

    .profile-tab-btn {
      .relative-date {
        font-size: var(--font-down-3);
        color: var(--primary-medium);
      }
      justify-content: unset;
      line-height: var(--line-height-large);
      width: 100%;

      .d-icon {
        padding: 0;
      }
    }

    .do-not-disturb {
      .d-icon-toggle-on {
        color: var(--tertiary);
      }
    }

    hr {
      border-top: 1px solid var(--primary-low);
      width: 100%;
    }
  }
}

.hamburger-panel {
  .panel-body {
    overflow-y: auto;
  }
}

.menu-links.columned {
  li {
    width: 50%;
    float: left;
  }
}

.menu-panel {
  // remove once glimmer search menu in place
  .widget-link,
  .categories-link {
    padding: 0.25em 0.5em;
    display: block;
    color: var(--primary);

    &:hover,
    &:focus {
      background-color: var(--d-hover);
      outline: none;
    }

    .d-icon {
      color: var(--primary-medium);
    }

    .new {
      font-size: var(--font-down-1);
      margin-left: 0.5em;
      color: var(--primary-med-or-secondary-med);
    }

    &.show-help,
    &.filter {
      color: var(--tertiary);
    }
  }

  .search-link,
  .categories-link {
    padding: 0.25em 0.5em;
    display: block;
    color: var(--primary);

    &:hover,
    &:focus {
      background-color: var(--d-hover);
      outline: none;
    }

    .d-icon {
      color: var(--primary-medium);
    }

    .new {
      font-size: var(--font-down-1);
      margin-left: 0.5em;
      color: var(--primary-med-or-secondary-med);
    }

    &.show-help,
    &.filter {
      color: var(--tertiary);
    }
  }

  li.category-link {
    float: left;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    padding: 0.25em 0.5em;
    width: 50%;
    box-sizing: border-box;

    a {
      display: inline-flex;

      &:hover,
      &:focus {
        background: transparent;

        .category-name {
          color: var(--primary);
        }
      }
    }

    .badge-notification {
      color: var(--primary-med-or-secondary-med);
      background-color: transparent;
      display: inline;
      padding: 0;
      font-size: var(--font-down-1);
      line-height: var(--line-height-large);
    }
  }

  // note these topic counts only appear for anons in the category hamburger drop down
  b.topics-count {
    color: var(--primary-med-or-secondary-med);
    font-weight: normal;
    font-size: var(--font-down-1);
  }

  div.discourse-tags {
    font-size: var(--font-down-1);
  }

  .sidebar-panel-header__row {
    width: 100%;
  }

  .sidebar-sections {
    &__back-to-forum {
      color: var(--d-sidebar-link-color);
      display: flex;
      align-items: center;

      svg {
        margin-right: var(--d-sidebar-section-link-prefix-margin-right);
        height: 0.75em;
        width: 0.75em;
        color: var(--d-sidebar-link-icon-color);
      }
    }
  }
}

// Panel / user-notification-list styles. **not** menu panel sizing styles
.user-menu .quick-access-panel,
.user-notifications-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  max-height: 100%;
  border-top: 1px solid var(--primary-low);
  padding-top: 0.75em;
  margin-top: -1px;

  &:focus {
    outline: none;
  }

  .double-user,
  .multi-user {
    white-space: unset;
  }

  .item-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--primary);
  }

  h3 {
    padding: 0 0.4em;
    font-weight: bold;
    margin: 0.5em 0;
  }

  .d-icon,
  &:hover .d-icon {
    color: var(--primary-medium);
  }

  .icon {
    color: var(--primary-high);
  }

  .btn-primary {
    .d-icon {
      color: var(--secondary);
    }
  }

  ul {
    display: flex;
    flex-flow: column wrap;
    overflow: hidden;
    max-height: 100%;
  }

  li {
    background-color: var(--secondary);
    box-sizing: border-box;
    list-style-type: none;

    &.unread,
    &.pending {
      background-color: var(--tertiary-low);
    }

    &:hover {
      background-color: var(--d-hover);
      outline: none;
    }

    &:focus-within {
      background: var(--d-hover);

      a {
        // we don't need the link focus because we're styling the parent
        outline: 0;
      }
    }

    // This is until other languages remove the HTML from within
    // notifications. It can then be removed
    div .fa {
      display: none;
    }

    span.double-user,
    // e.g., "username, username2"
    span.multi-user
    // e.g., "username and n others"
    {
      display: inline;
      max-width: 100%;
      align-items: baseline;
      white-space: nowrap;
    }

    span.multi-user
    // e.g., "username, username2, and n others"
    {
      span.multi-username:nth-of-type(2) {
        // margin between username2 and "and n others"
        margin-right: 0.25em;
      }
    }

    // truncate when usernames are very long
    span.multi-username {
      @include ellipsis;
      flex: 0 1 auto;
      min-width: 1.2em;
      max-width: 10em;

      &:nth-of-type(2) {
        // margin for comma between username and username2
        margin-left: 0.25em;
      }
    }

    &:hover {
      background-color: var(--d-hover);
      outline: none;
    }

    &:focus-within {
      background: var(--d-hover);

      a {
        // we don't need the link focus because we're styling the parent
        outline: 0;
      }

      .btn-flat:focus {
        // undo default btn-flat style
        background: transparent;
      }
    }

    a,
    .profile-tab-btn {
      display: flex;
      margin: 0.25em;
      padding: 0 0.25em;
    }

    button {
      padding: 0.25em 0.5em;
    }

    a,
    button {
      > div {
        overflow: hidden; // clears the text from wrapping below icons
        overflow-wrap: anywhere;
        @supports not (overflow-wrap: anywhere) {
          word-break: break-word;
        }

        // Truncate items with more than 2 lines.
        @include line-clamp(2);
      }
    }

    p {
      margin: 0;
      overflow: hidden;
    }
  }

  li:not(.show-all) {
    padding: 0;
    align-self: flex-start;
    width: 100%;

    .d-icon {
      padding-top: 0.2em;
      margin-right: 0.5em;
    }
  }

  .is-warning {
    .d-icon-envelope {
      color: var(--danger);
    }
  }

  .read {
    background-color: var(--secondary);
  }

  .none {
    padding-top: 5px;
  }

  .spinner-container {
    min-height: 2em;
  }

  .spinner {
    width: 20px;
    height: 20px;
    border-width: 2px;
    margin: 0 auto;
  }

  .show-all a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    color: var(--primary-med-or-secondary-high);
    background: var(--blend-primary-secondary-5);

    &:hover {
      color: var(--primary);
      background: var(--primary-low);
    }
  }

  /* as a big ol' click target, don't let text inside be selected */
  @include unselectable;
}

// Styles to have user avatar positioned and sized correctly
.user-menu.show-avatars,
.user-notifications-list.show-avatars {
  li.notification,
  li.bookmark,
  li.reviewable,
  li.message {
    a {
      color: var(--primary);
      align-items: center;
      padding: 0.15em 0;

      .item-label {
        font-weight: bold;
      }

      .item-description {
        color: var(--primary);
      }

      .icon-avatar {
        display: flex;
        position: relative;
        overflow: visible;
        margin-right: 0.5em;
        flex-shrink: 0;
        width: 2em;
        height: 2em;
        margin: 0.3em 1em 0 0;

        .avatar {
          width: 100%;
          height: 100%;
        }

        &__icon-wrapper {
          position: absolute;
          right: -0.65em;
          top: -0.45em;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 22px;
          height: 22px;
          border-radius: 100%;
          background: var(--secondary);

          .d-icon {
            display: block;
            margin: 0;
            padding: 0;
            font-size: var(--font-down-1);
            color: var(--primary);
          }
        }
      }

      & + div {
        padding: 0.25em 0;
      }
    }

    &.unread .icon-avatar__icon-wrapper {
      background: var(--tertiary);

      .d-icon {
        color: var(--secondary);
      }
    }
  }
}

.hamburger-panel .menu-panel.slide-in {
  left: 0;

  .panel-body {
    display: block;
  }

  .panel-body-contents {
    max-height: unset;
    min-height: 100%;
  }
}

.header-cloak {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  display: none;
  touch-action: pan-y pinch-zoom;
}

.menu-panel.slide-in {
  top: 0;
  box-sizing: border-box;

  // ensure there's always space to click outside on tiny devices
  max-width: 90vw;

  --100dvh: 100%;
  @supports (height: 100dvh) {
    --100dvh: 100dvh;
  }
  box-shadow: 0 0 30px -2px rgba(0, 0, 0, 0.5);
  height: var(--100dvh);
}
