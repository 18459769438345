/*
* shared styles
*/

.d-modal.login-modal,
.d-modal.create-account {
  &.awaiting-approval {
    display: none;
  }

  .d-modal {
    &__container {
      position: relative;
      width: 100%;
    }

    &__header {
      border-bottom: none;
      padding: 0;
      position: absolute;
      top: 0.75em;
      right: 0.75em;
      z-index: z("max");
    }

    &__body {
      display: flex;
      gap: 2rem;
      padding: 0;
    }

    &__footer {
      flex-wrap: nowrap;
      padding: 0;
      border: 0;

      .inline-spinner {
        display: inline-flex;
      }
    }
  }

  .login-left-side {
    box-sizing: border-box;
    width: 100%;
    padding: 3rem;
    overflow: auto;
  }

  .login-right-side {
    background: var(--tertiary-or-tertiary-low);
    padding: 3.5rem 3rem;
    max-width: 16em;
  }

  .btn-social-title {
    @include ellipsis;
  }

  #login-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    height: 100%;
    white-space: nowrap;
  }

  #login-form {
    margin: 2em 0 1em 0;
    display: flex;
    flex-direction: column;

    .create-account-associate-link {
      order: 1;
    }
  }

  .tip {
    font-size: var(--font-down-1);
    min-height: 1.4em;
    display: block;

    &.bad {
      color: var(--danger);
    }
  }

  .toggle-password-mask span {
    font-size: var(--font-down-1-rem);
  }

  .more-info,
  .instructions {
    font-size: var(--font-down-1);
    color: var(--primary-medium);
    overflow-wrap: anywhere;
  }

  .caps-lock-warning {
    color: var(--danger);
    font-size: var(--font-down-1);
    font-weight: bold;
    margin-top: 0.5em;
  }

  #modal-alert {
    box-sizing: border-box;
    display: inline-block;

    // if you want to use flexbox here make sure child elements like <b> don't cause line breaks
    padding: 1em 3.5em 1em 1em; // large right padding to make sure long text isn't under the close button
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    min-height: 35px;

    &:empty {
      min-height: 0;
      padding: 0;
      overflow: hidden;
      display: inline;
    }
  }

  .login-page-cta,
  .signup-page-cta {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__disclaimer {
      color: var(--primary-medium);
      margin-bottom: 1rem;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      button {
        font-size: var(--font-0) !important;
        width: 100%;
      }
    }

    &__existing-account,
    &__no-account-yet {
      color: var(--primary-medium);
      font-size: var(--font-down);
      margin-bottom: 0.5rem;
      text-align: center;
      width: 100%;

      &::before {
        content: " ";
        display: block;
        height: 1px;
        width: 100%;
        background-color: var(--primary-low);
        margin-block: 1rem;
      }
    }
  }

  .desktop-view & {
    @media screen and (max-width: 767px) {
      // important to maintain narrow desktop widths
      // for auth modals in Discourse Hub on iPad
      .d-modal__header {
        right: 0;
        top: 0;
      }

      .has-alt-auth {
        flex-direction: column;
        overflow: auto;
        gap: 0;

        .d-modal__footer,
        .btn-social {
          font-size: var(--font-down-1);
        }

        .login-left-side {
          overflow: unset;
          padding: 1em;
        }

        .login-right-side {
          padding: 1em;
          max-width: unset;
        }

        #login-form {
          margin: 1.5em 0;
        }

        .signup-progress-bar {
          display: none;
        }
      }
    }
  }
}

.invites-show {
  #account-email-validation:not(:has(*)) {
    display: none;
  }
}

/* end shared styles */
.d-modal.create-account,
.d-modal.login-modal {
  &:not(:has(.login-right-side)) .d-modal__container {
    max-width: 500px;
  }
}

.d-modal.create-account {
  .d-modal {
    &__container {
      width: 100%;
    }

    &__footer {
      flex-direction: column;
      align-items: flex-start;
    }

    &__footer-buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;

      .already-have-account {
        color: var(--primary-medium);
        margin-bottom: 0.5em;
      }

      button,
      hr {
        width: 100%;
      }
    }
  }

  .disclaimer {
    color: var(--primary-medium);
    margin-bottom: 0.5em;
  }

  .create-account__password-info {
    display: flex;
    justify-content: space-between;

    .create-account__password-tip-validation {
      display: flex;
    }
  }
}

// Login Form Styles
.login-modal,
.create-account,
.invites-show {
  #login-form,
  .login-form,
  .invite-form {
    .input-group {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 1em;

      input,
      .select-kit-header {
        padding: 0.75em 0.77em;
        min-width: 250px;
        margin-bottom: 0.25em;
        width: 100%;
      }

      input:focus {
        outline: none;
        border: 1px solid var(--tertiary);
        box-shadow: 0 0 0 2px rgba(var(--tertiary-rgb), 0.25);
      }

      input:disabled {
        background-color: var(--primary-low);
      }

      span.more-info {
        color: var(--primary-medium);
        min-height: 1.4em; // prevents height increase due to tips
        overflow-wrap: anywhere;
      }

      label.alt-placeholder,
      .user-field.text label.control-label,
      .user-field.dropdown label.control-label,
      .user-field.multiselect label.control-label {
        color: var(--primary-medium);
        font-size: 16px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 1em;
        top: 13px;
        box-shadow: 0 0 0 0 rgba(var(--tertiary-rgb), 0);
        transition: 0.2s ease all;
      }

      .user-field.text label.control-label,
      .user-field.dropdown label.control-label,
      .user-field.multiselect label.control-label {
        z-index: 999;
        top: -8px;
        left: calc(1em - 0.25em);
        background-color: var(--secondary);
        padding: 0 0.25em 0 0.25em;
        font-size: $font-down-1;
      }

      .user-field.text label.control-label {
        top: 13px;
      }

      .user-field.text:focus-within,
      .user-field.dropdown:focus-within,
      .user-field.multiselect:focus-within {
        z-index: 1000; // ensures the active input is always on top of sibling input labels
      }

      input:focus + label.alt-placeholder,
      input.value-entered + label.alt-placeholder {
        top: -8px;
        left: calc(1em - 0.25em);
        background-color: var(--secondary);
        padding: 0 0.25em 0 0.25em;
        font-size: var(--font-down-1);
      }

      input.alt-placeholder:invalid {
        color: var(--primary);
      }

      .user-field.dropdown,
      .user-field.multiselect {
        .more-info,
        .instructions {
          opacity: 1;
        }
      }

      #email-login-link {
        transition: opacity 0.5s;

        &.no-login-filled {
          opacity: 0;
          visibility: hidden;
        }
      }

      #email-login-link,
      .login__password-links {
        font-size: var(--font-down-1);
        display: flex;
        justify-content: space-between;
      }

      .tip:not(:empty) + label.more-info {
        display: none;
      }
    }

    #second-factor {
      input {
        width: 100%;
        padding: 0.75em 0.5em;
        min-width: 250px;
        box-shadow: none;
      }

      input:focus {
        outline: none;
        border: 1px solid var(--tertiary);
        box-shadow: 0 0 0 2px rgba(var(--tertiary-rgb), 0.25);
      }
    }

    // user fields input groups will
    // be styled differently
    .user-fields .input-group {
      .user-field {
        &.text {
          &.value-entered label.alt-placeholder.control-label,
          input:focus + label.alt-placeholder.control-label {
            top: -8px;
            left: calc(1em - 0.25em);
            background-color: var(--secondary);
            padding: 0 0.25em 0 0.25em;
            font-size: 14px;
            color: var(--primary-medium);
          }

          label.alt-placeholder.control-label {
            color: var(--primary-medium);
            font-size: 16px;
            position: absolute;
            pointer-events: none;
            transition: 0.2s ease all;
            max-width: calc(100% - 2em);
            white-space: nowrap;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        details:not(.has-selection) span.name,
        details:not(.has-selection) span.formatted-selection {
          color: var(--primary-medium);
        }

        .select-kit-row span.name {
          color: var(--primary);
        }

        .select-kit.combo-box.is-expanded summary {
          outline: none;
          border: 1px solid var(--tertiary);
          box-shadow: 0 0 0 2px rgba(var(--tertiary-rgb), 0.25);
        }

        .controls .checkbox-label {
          display: flex;
          align-items: center;

          input[type="checkbox"].ember-checkbox {
            width: 1em !important;
            min-width: unset;
            margin-block: 0;
          }
        }
      }
    }
  }
}
