.select-kit {
  &.combo-box {
    &.category-drop {
      min-width: auto;

      .badge-category__wrapper {
        font-size: var(--font-0);
        font-weight: normal;
        max-width: 260px;
      }

      .category-drop-header {
        &[data-value=""] {
          color: var(--primary-high);
        }

        &.is-none .selected-name {
          color: inherit;
        }
      }

      .caret-icon {
        color: var(--primary-high);
      }

      &.has-selection {
        .category-drop-header {
          border-color: var(--d-nav-border-color--active);
        }
      }

      .select-kit-row {
        flex-direction: column;
        align-items: flex-start;

        &[data-value="all-categories"],
        &[data-value="no-categories"] {
          color: var(--tertiary);
          font-weight: 700;
        }

        .category-desc {
          font-weight: normal;
          color: var(--primary-medium);
          margin-top: 2px;
        }

        .category-status {
          align-items: center;
        }

        .topic-count {
          margin-left: 0.5em;
          font-weight: normal;
          color: var(--primary-medium);
          font-size: var(--font-down-1);
        }
      }

      .category-drop-footer {
        align-items: center;
        border-top: 1px solid var(--primary-low);
        display: flex;
        font-size: var(--font-down-1);
        height: 30px;
        justify-content: space-between;
        width: 100%;

        a,
        span {
          color: var(--primary-high);
          margin: 0 10px;

          &.active {
            display: none;
          }
        }
      }
    }
  }
}
