.admin-customize {
  .show-current-style {
    padding-left: 2%;
    width: 68%;

    .title {
      input {
        width: 80%;
      }
    }
  }

  .themes-list {
    width: 28%;
  }
}
