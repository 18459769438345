.explain-reviewable {
  min-width: 500px;

  .thresholds {
    margin-top: 1em;
  }

  table {
    width: 100%;
  }

  table td {
    padding: 0.5em;
  }

  td.sum {
    text-align: right;
  }

  td.sum.total {
    font-weight: bold;
  }

  tr.total {
    td {
      background-color: var(--primary-low);
      font-weight: bold;
    }
  }

  .op {
    font-weight: bold;
  }

  .score-value-type {
    color: var(--primary-medium);
  }

  .op:first-of-type {
    display: none;
  }
}
