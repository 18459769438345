#topic-entrance {
  border: 1px solid var(--primary-low);
  padding: 5px;
  background: var(--secondary);
  box-shadow: var(--shadow-card);
  width: 133px;
  @include unselectable;

  &:not(.--glimmer) {
    z-index: z("dropdown");
    position: absolute;

    button.full .d-icon {
      display: block;
      width: 100%;
    }
  }

  &.--glimmer {
    button.full {
      display: flex;
      flex-direction: column;
    }
  }

  button.full {
    width: 100%;
    margin-bottom: 5px;
    flex-wrap: wrap;

    .d-icon {
      margin: 2px auto;
      transform: rotate(90deg);
    }
  }

  button.btn.jump-bottom {
    margin: 5px 0 0 0;
  }
}
