:root {
  --d-sidebar-section-link-prefix-margin-right: 0.75em;
  --d-sidebar-section-link-prefix-width: 1.35rem;
  --d-sidebar-section-link-icon-size: 0.8em;
}

.sidebar-section-link-wrapper {
  display: flex;
  align-items: center;
  position: relative;

  &[data-list-item-name="all-categories"],
  &[data-list-item-name="all-tags"],
  &[data-list-item-name="configure-default-navigation-menu-tags"] {
    font-size: var(--font-down-1-rem);
  }

  .sidebar-section-link-prefix {
    .prefix-image {
      border: 1px solid transparent;
    }

    &.active .prefix-image {
      box-shadow: 0 0 0 1px var(--success);
    }
  }

  .sidebar-section-link {
    display: inline-flex;
    width: 100%;
    align-items: center;
    color: var(--d-sidebar-link-color);
    transition: background-color 0.25s;

    &:focus,
    &:hover {
      background: var(--d-sidebar-highlight-background);
      color: var(--d-sidebar-highlight-color);
      outline: none;

      .prefix-text {
        background: var(--d-sidebar-highlight-prefix-background);
      }

      .sidebar-section-link-suffix.icon.unread svg {
        color: var(--d-sidebar-highlight-suffix-color);
      }
    }

    &--active,
    &.active {
      background: var(--d-sidebar-active-background);
      color: var(--d-sidebar-active-color);
      font-weight: bold;

      .sidebar-section-link-prefix {
        &.icon {
          color: var(--d-sidebar-active-color);
        }
      }

      .prefix-text {
        background: var(--d-sidebar-active-prefix-background);
      }

      .sidebar-section-link-content-badge {
        color: var(--d-sidebar-active-color);
      }

      .sidebar-section-link-suffix.icon.unread svg {
        color: var(--d-sidebar-active-suffix-color);
      }

      .sidebar-section-link-hover:hover .sidebar-section-hover-button {
        background-color: var(--d-sidebar-active-background);
      }
    }

    &--muted {
      opacity: 0.5;

      .sidebar-section-link-prefix.icon .d-icon {
        color: var(--primary-medium);
      }

      &.active {
        .sidebar-section-link-prefix.icon .d-icon {
          color: var(--primary-high);
        }
      }
    }

    .sidebar-section-link-content-badge {
      @include ellipsis;
      padding-left: 0.5em;
      padding-right: 0.1em; // avoids some overflow cropping
      text-align: right;
      color: var(--d-sidebar-link-badge-color);
      font-size: var(--font-down-2);
      font-weight: normal;
      margin-left: auto;
    }

    .sidebar-section-link-suffix {
      margin-left: 1em;
      font-size: var(--font-down-4);

      &.icon {
        &.urgent svg {
          color: var(--success);
        }

        &.unread svg {
          color: var(--d-sidebar-suffix-color);
        }
      }
    }

    .sidebar-section-link-content-text {
      @include ellipsis;

      .emoji {
        width: 15px;
        height: 15px;
        vertical-align: baseline;
      }

      .badge-category__wrapper {
        font-size: 100%;
        width: 100%;

        .badge-category__name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .--link-button {
    border: none;
    background: inherit;
  }

  .sidebar-section-link[data-link-name="personal-messages-sent"],
  .sidebar-section-link[data-link-name="personal-messages-new"],
  .sidebar-section-link[data-link-name="personal-messages-archive"],
  .sidebar-section-link[data-link-name="personal-messages-unread"],
  .sidebar-section-link[data-link-name="group-messages-new"],
  .sidebar-section-link[data-link-name="group-messages-unread"],
  .sidebar-section-link[data-link-name="group-messages-archive"] {
    .sidebar-section-link-content-text {
      margin-left: calc(
        var(--d-sidebar-section-link-prefix-margin-right) +
          var(--d-sidebar-section-link-prefix-width)
      );
    }
  }

  .sidebar-section-link-review {
    .sidebar-section-link-content-badge {
      color: var(--danger);
    }
  }

  .sidebar-section-link-prefix {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: var(--d-sidebar-section-link-prefix-width);
    height: var(--d-sidebar-section-link-prefix-width);
    margin-right: var(--d-sidebar-section-link-prefix-margin-right);

    &.image {
      img {
        border-radius: 100%;
        aspect-ratio: auto 20 / 20;
        width: calc(var(--d-sidebar-section-link-prefix-width) - 2px);
        height: calc(var(--d-sidebar-section-link-prefix-width) - 2px);
      }
    }

    &.text {
      text-align: center;
    }

    &.icon,
    &.span {
      position: relative;
      color: var(--d-sidebar-link-icon-color);

      svg {
        font-size: var(--d-sidebar-section-link-icon-size);
      }

      .prefix-badge {
        position: absolute;
        background-color: transparent;
        border-radius: 50%;
        padding: 2px 2px 3px;
        color: var(--d-sidebar-link-color);
        height: 0.5rem;
        width: 0.5rem;
        top: -0.2em;
        right: 0;
        margin-right: -0.2em;
      }
    }

    .prefix-span {
      width: 0.8em;
      height: 0.8em;
    }
  }

  .prefix-text {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: var(--d-sidebar-prefix-background);
    color: var(--d-sidebar-prefix-color);
    width: calc(var(--d-sidebar-section-link-prefix-width) - 2px);
    height: calc(var(--d-sidebar-section-link-prefix-width) - 2px);
    font-size: var(--font-down-2);
  }

  .sidebar-section-link-hover {
    margin-left: auto;

    .sidebar-section-hover-button {
      display: none;
      color: var(--d-sidebar-highlight-color);
      border: none;
      background: transparent;
      padding: 0 0 0 0.5em;
      height: 100%;
      transition: background-color 0.25s;

      &:focus,
      .discourse-no-touch & {
        &:hover {
          color: var(--d-sidebar-highlight-color);
        }
      }

      svg {
        font-size: var(--font-down-1);
      }
    }

    &:hover {
      .sidebar-section-hover-button {
        background: var(--d-sidebar-highlight-background);
      }
    }
  }

  &:hover {
    .sidebar-section-hover-button {
      display: block;
    }
  }
}
